.main-header {
  z-index: 100;
  font-family: $font-secondary;

  &__top {
    padding: rem(15) 0;
    background: $color--primary;
    font-weight: 700;
    @include font(14, 22);
    color: $color--light;
    z-index: 1;
    position: relative;

    span {
      display: block;
      text-align: center;

      span {
        display: inline-block;
        width: 30px;
      }
    }
  }

  &__main {
    padding-top: rem(18);
    position: absolute;
    width: 100%;
    left: 0;
    background: $color--3;
    z-index: 2;

    &.sticky {
      z-index: 999;
      position: fixed;
      top: 0;
    }

    @media (min-width: $lg) {
      padding-top: rem(0);
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // max-width: calc(1250px + rem($container-padding) * 2);
    padding: 1rem rem(60) 1rem rem(20);

    @media (min-width: $lg) {
      justify-content: space-between;
    }

    .price {
      position: relative;
      margin-left: rem(8);
      font-weight: 700;
      color: $color--5;
      display: flex;
      align-items: center;
      @media (min-width: $xl) {
        margin-left: rem(16);
        &::before {
          content: "";
          display: block;
          background: rgba($color--light, 0.5);
          width: rem(0.5);
          height: rem(36);
          position: absolute;
          left: -16px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .pulse-anim {
        display: block;
        margin: 0 0 0 rem(5);
      }
    }
  }

  &__logo {
    max-width: rem(171);
    z-index: 2;
    img {
      max-height: 45px;
    }

    @media (min-width: $lg) {
      max-width: rem(204);
    }
  }

  &__button {
    display: flex;

    @media (min-width: $lg) {
      position: relative;
      align-items: center;
      padding-left: rem(20);
    }

    .btn {
      padding: rem(8) rem(8);
      height: rem(38);
      @media (min-width: $lg) {
        @include font(14, 22);
        padding: rem(8) rem(12.18);
        height: rem(38);
      }
    }
  }

  .hamburger {
    position: relative;
    width: rem(40);
    height: rem(20);
    z-index: 500;

    @media (min-width: $xl) {
      display: none;
    }

    &.open {
      left: rem(45);
      span {
        &:nth-child(1) {
          transform: rotate(45deg) translateY(-7px);
        }
        &:nth-child(2) {
          opacity: 0;
          visibility: hidden;
        }
        &:nth-child(3) {
          transform: rotate(-45deg) translateY(7px);
        }
      }
    }

    span {
      display: block;
      height: rem(2);
      background: $color--light;
      width: 100%;
      position: absolute;
      transition: 0.25s ease-in-out;
      transform-origin: left center;

      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);
      }
      &:nth-child(3) {
        bottom: 0;
      }
    }
  }

  &__nav {
    position: fixed;
    background: $color--3;
    top: 0;
    right: 0;
    bottom: 0;
    text-align: right;
    overflow: hidden;
    transition: width 0.25s ease-in-out;
    padding: rem(200) 0 rem(40);
    width: 0;
    z-index: 2;

    &.open {
      width: 100%;
    }

    @media (min-width: $xl) {
      background: transparent;
      position: relative;
      width: auto;
      text-align: left;
      z-index: initial;
      transition: auto;
      padding: 0;
      top: initial;
      right: initial;
      bottom: initial;
      overflow: initial;
      flex: 1;
      padding-right: rem(20);
      min-width: fit-content;
    }

    .menu {
      padding: 0 rem(16);
      height: 100%;
      @include scrollbar-hidden();
      overflow-x: hidden;
      min-width: rem(260);

      @media (min-width: $xl) {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        padding: 0;
        overflow: initial;
        min-width: initial;
        justify-content: start;
        gap: rem(5) rem(30);
      }

      @media (min-width: $xl) {
        // gap: rem(5) rem(62);
      }

      &-item {
        margin-bottom: rem(20);
        padding-right: rem(7);
        &:last-child {
          margin-bottom: 0;
        }

        &.active {
          a {
            @include link-underline();
          }
        }

        @media (min-width: $xl) {
          margin-bottom: 0;
        }

        a {
          @include link();
          color: rgba($color--light, 0.75);
          @include font(22, 28);
          font-weight: 300;

          &:hover {
            color: $color--light;
            text-decoration: none;
          }

          @media (min-width: $xl) {
            @include font(16, 22);
            color: $color--light;
          }
        }
      }
    }
  }
}
