.description-image {
	padding: rem(48) 0;

	@media (min-width: $lg) {
		padding: rem(110) 0;
	}

	.container {
		max-width: rem(1292);
	}

	.section {
		&__header {
			margin-bottom: rem(33);

			@media (min-width: $lg) {
				max-width: rem(927);
				margin-bottom: rem(52);
			}
		}

		&__description {
			@media (min-width: $lg) {
				max-width: rem(927);
			}
		}
	}

	&__button {
		margin-top: rem(33);
		display: flex;
		justify-content: center;

		@media (min-width: $lg) {
			margin-top: 0;
		}

		.btn {
			@media (min-width: $lg) {
				min-width: rem(208);
			}
		}
	}
}