@font-face {
  font-family: SF Pro Display;
  src: url("SF-Pro-Display-Bold.50e64b39.woff2") format("woff2"), url("SF-Pro-Display-Bold.50e64b39.woff2") format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: SF Pro Display;
  src: url("SF-Pro-Display-Semibold.2567dc8b.woff2") format("woff2"), url("SF-Pro-Display-Semibold.44000779.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: SF Pro Display;
  src: url("SF-Pro-Display-Regular.22c7d222.woff2") format("woff2"), url("SF-Pro-Display-Regular.3e45ea3e.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: SF Pro Display;
  src: url("SF-Pro-Display-Light.b6635f74.woff2") format("woff2"), url("SF-Pro-Display-Light.83ffc431.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

.pulse-anim {
  opacity: .5;
  animation: 3s ease-out infinite pulse-anim;
}

@keyframes pulse-anim {
  0% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
}

.container {
  max-width: 74rem;
  margin: 0 2.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container__no-max-width {
  margin: 0 1.6875rem 0 2.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container__no_margin {
  max-width: 74rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.container__end {
  justify-content: flex-end;
  padding: 0;
  display: flex;
}

.container-center {
  max-width: 90rem;
  margin: 0 auto;
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.row {
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
}

.row--align-center {
  flex-wrap: wrap;
  align-items: center;
  margin-left: -1rem;
  margin-right: -1rem;
  display: flex;
}

.col {
  flex: 1 1 0;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .widget__desktop {
    display: none;
  }
}

[class*="col-"] {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 1rem;
}

.col-1 {
  max-width: 8.33333%;
  flex-basis: 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex-basis: 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex-basis: 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex-basis: 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex-basis: 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex-basis: 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex-basis: 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex-basis: 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex-basis: 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex-basis: 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex-basis: 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex-basis: 100%;
}

@media (min-width: 768px) {
  .col-md-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex-basis: 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex-basis: 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex-basis: 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex-basis: 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex-basis: 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex-basis: 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex-basis: 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex-basis: 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 992px) {
  .col-lg-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex-basis: 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex-basis: 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex-basis: 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex-basis: 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex-basis: 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex-basis: 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex-basis: 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex-basis: 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 1200px) {
  .col-xl-1 {
    max-width: 8.33333%;
    flex-basis: 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex-basis: 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex-basis: 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex-basis: 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex-basis: 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex-basis: 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex-basis: 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex-basis: 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex-basis: 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex-basis: 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex-basis: 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

html, body, h1, h2, h3, p, a, img, header, footer, nav, ol, ul {
  font-size: 100%;
  font: inherit;
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  color: #000000bf;
  scrollbar-width: thin;
  scrollbar-color: #06233f #b0c9e8;
  font-family: SF Pro Display, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  overflow-y: auto;
}

body::-webkit-scrollbar {
  width: .5rem;
}

body::-webkit-scrollbar-track {
  background: #b0c9e8;
}

body::-webkit-scrollbar-thumb {
  background-color: #06233f;
  border: 0;
  border-radius: 0;
}

body.overflow {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-family: SF Pro Display, sans-serif;
  font-weight: 600;
}

h1, .h1 {
  font-size: 2.375rem;
  font-weight: 700;
  line-height: 1.05263;
}

@media (min-width: 992px) {
  h1, .h1 {
    font-size: 3.75rem;
    line-height: .933333;
  }
}

h2, .h2 {
  font-size: 1.875rem;
  line-height: 1.26667;
}

@media (min-width: 992px) {
  h2, .h2 {
    font-size: 2.875rem;
    line-height: 1.17391;
  }
}

h3, .h3 {
  font-size: 2.5rem;
  line-height: 1.2;
}

@media (min-width: 992px) {
  h3, .h3 {
    font-size: 2.8125rem;
    line-height: 1.02222;
  }
}

h4, .h4 {
  font-size: 1.375rem;
  line-height: 1.27273;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1.125rem;
}

a {
  color: inherit;
  text-decoration: none;
}

a:not([class]):hover {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
  text-decoration: underline;
}

img, svg {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ol, ul {
  list-style: none;
}

input, button {
  all: unset;
}

input, textarea {
  cursor: text;
}

input::placeholder, textarea::placeholder {
  color: #000;
}

input:focus::placeholder, textarea:focus::placeholder {
  color: #0000;
}

input[type="submit"], button {
  cursor: pointer;
}

.overflow {
  overflow: hidden;
}

textarea {
  font-family: SF Pro Display, sans-serif;
}

main {
  overflow: hidden;
}

@media (min-width: 992px) {
  main {
    padding-top: 4.375rem;
  }
}

.anchor {
  scroll-margin-top: 4rem;
}

.screen-reader-text {
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.main-header {
  z-index: 100;
  font-family: Inter, sans-serif;
}

.main-header__top {
  color: #fff;
  z-index: 1;
  background: #017aff;
  padding: .9375rem 0;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.57143;
  position: relative;
}

.main-header__top span {
  text-align: center;
  display: block;
}

.main-header__top span span {
  width: 30px;
  display: inline-block;
}

.main-header__main {
  width: 100%;
  z-index: 2;
  background: #154777;
  padding-top: 1.125rem;
  position: absolute;
  left: 0;
}

.main-header__main.sticky {
  z-index: 999;
  position: fixed;
  top: 0;
}

@media (min-width: 992px) {
  .main-header__main {
    padding-top: 0;
  }
}

.main-header__container {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3.75rem 1rem 1.25rem;
  display: flex;
}

@media (min-width: 992px) {
  .main-header__container {
    justify-content: space-between;
  }
}

.main-header__container .price {
  color: #d4af37;
  align-items: center;
  margin-left: .5rem;
  font-weight: 700;
  display: flex;
  position: relative;
}

@media (min-width: 1200px) {
  .main-header__container .price {
    margin-left: 1rem;
  }

  .main-header__container .price:before {
    content: "";
    width: .03125rem;
    height: 2.25rem;
    background: #ffffff80;
    display: block;
    position: absolute;
    top: 50%;
    left: -16px;
    transform: translateY(-50%);
  }
}

.main-header__container .price .pulse-anim {
  margin: 0 0 0 .3125rem;
  display: block;
}

.main-header__logo {
  max-width: 10.6875rem;
  z-index: 2;
}

.main-header__logo img {
  max-height: 45px;
}

@media (min-width: 992px) {
  .main-header__logo {
    max-width: 12.75rem;
  }
}

.main-header__button {
  display: flex;
}

@media (min-width: 992px) {
  .main-header__button {
    align-items: center;
    padding-left: 1.25rem;
    position: relative;
  }
}

.main-header__button .btn {
  height: 2.375rem;
  padding: .5rem;
}

@media (min-width: 992px) {
  .main-header__button .btn {
    height: 2.375rem;
    padding: .5rem .76125rem;
    font-size: .875rem;
    line-height: 1.57143;
  }
}

.main-header .hamburger {
  width: 2.5rem;
  height: 1.25rem;
  z-index: 500;
  position: relative;
}

@media (min-width: 1200px) {
  .main-header .hamburger {
    display: none;
  }
}

.main-header .hamburger.open {
  left: 2.8125rem;
}

.main-header .hamburger.open span:nth-child(1) {
  transform: rotate(45deg)translateY(-7px);
}

.main-header .hamburger.open span:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.main-header .hamburger.open span:nth-child(3) {
  transform: rotate(-45deg)translateY(7px);
}

.main-header .hamburger span {
  height: .125rem;
  width: 100%;
  transform-origin: 0;
  background: #fff;
  transition: all .25s ease-in-out;
  display: block;
  position: absolute;
}

.main-header .hamburger span:nth-child(1) {
  top: 0;
}

.main-header .hamburger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.main-header .hamburger span:nth-child(3) {
  bottom: 0;
}

.main-header__nav {
  text-align: right;
  width: 0;
  z-index: 2;
  background: #154777;
  padding: 12.5rem 0 2.5rem;
  transition: width .25s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.main-header__nav.open {
  width: 100%;
}

@media (min-width: 1200px) {
  .main-header__nav {
    width: auto;
    text-align: left;
    z-index: initial;
    top: initial;
    right: initial;
    bottom: initial;
    overflow: initial;
    min-width: -moz-fit-content;
    min-width: fit-content;
    background: none;
    flex: 1;
    padding: 0 1.25rem 0 0;
    transition: auto;
    position: relative;
  }
}

.main-header__nav .menu {
  height: 100%;
  scrollbar-width: none;
  min-width: 16.25rem;
  padding: 0 1rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-header__nav .menu::-webkit-scrollbar {
  width: 0;
  display: none;
}

@media (min-width: 1200px) {
  .main-header__nav .menu {
    overflow: initial;
    min-width: initial;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: .3125rem 1.875rem;
    padding: 0;
    display: flex;
  }
}

.main-header__nav .menu-item {
  margin-bottom: 1.25rem;
  padding-right: .4375rem;
}

.main-header__nav .menu-item:last-child {
  margin-bottom: 0;
}

.main-header__nav .menu-item.active a {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  text-decoration: none;
  transition: background-size .2s, color .2s;
}

.main-header__nav .menu-item.active a:hover {
  background-position: 100% 100%;
  background-size: 0% 1px;
  text-decoration: none;
}

@media (min-width: 1200px) {
  .main-header__nav .menu-item {
    margin-bottom: 0;
  }
}

.main-header__nav .menu-item a {
  color: #ffffffbf;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  font-size: 1.375rem;
  font-weight: 300;
  line-height: 1.27273;
  transition: background-size .2s, color .2s;
}

.main-header__nav .menu-item a:hover {
  color: #fff;
  background-position: 0 100%;
  background-size: 100% 1px;
  text-decoration: none;
}

@media (min-width: 1200px) {
  .main-header__nav .menu-item a {
    color: #fff;
    font-size: 1rem;
    line-height: 1.375;
  }
}

.main-footer {
  background: #143255;
  font-family: Inter, sans-serif;
}

.main-footer__top {
  padding-top: 2rem;
  padding-bottom: 3.5rem;
}

@media (min-width: 992px) {
  .main-footer__top {
    padding-top: 3.75rem;
    padding-bottom: 4.9375rem;
  }
}

.main-footer__bottom {
  color: #ffffff80;
  padding: 1.5rem 0;
  font-size: 1rem;
  line-height: 1.25;
}

@media (min-width: 992px) {
  .main-footer__bottom .container {
    padding-left: 1.6875rem;
  }
}

.main-footer__bottom span {
  display: block;
}

@media (min-width: 992px) {
  .main-footer__container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.main-footer__container span {
  margin-top: 1rem;
}

.main-footer__row {
  display: flex;
}

.main-footer__logo {
  width: -moz-fit-content;
  width: fit-content;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 2rem;
  display: flex;
  position: relative;
}

.main-footer__logo-socials {
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  gap: 1.25rem;
  display: flex;
}

.main-footer__logo-subtitle {
  color: #d4af37;
  margin-top: .4375rem;
  margin-bottom: .4375rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375;
}

.main-footer__button.btn--arrow {
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.main-footer__divider {
  border: 1px solid #ffffff4d;
  margin-top: 2rem;
}

.main-footer__menu {
  flex-wrap: wrap;
  gap: 1.3125rem 0;
  margin: 2.25rem -1rem 0;
  padding-top: 2.1875rem;
  display: flex;
  position: relative;
}

.main-footer__menu:before {
  content: "";
  height: .0625rem;
  width: calc(100% - 32px);
  background: #ffffff80;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 992px) {
  .main-footer__menu:before {
    display: none;
  }

  .main-footer__menu {
    margin-top: 0;
    padding-top: 0;
  }
}

.main-footer__menu-item {
  max-width: 50%;
  flex-grow: 0;
  flex-basis: 50%;
  padding: 0 1rem;
}

@media (min-width: 992px) {
  .main-footer__menu-item {
    max-width: 33.3333%;
    flex-grow: 0;
    flex-basis: 33.3333%;
  }
}

.main-footer__menu-title {
  color: #fff;
  max-width: 8.375rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: Inter, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.2;
}

@media (min-width: 992px) {
  .main-footer__menu-title {
    max-width: 100%;
  }
}

.main-footer__menu-item--socials .main-footer__menu-title {
  margin-bottom: 1.5rem;
}

.main-footer__menu-title__margin-top {
  color: #fff;
  max-width: 8.375rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.33333;
}

@media (min-width: 992px) {
  .main-footer__menu-title__margin-top {
    max-width: 100%;
  }
}

.main-footer__menu-list {
  padding: 0 0 .5rem;
}

.main-footer__menu-link {
  color: #ffffff80;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.25;
}

.main-footer__menu-link:last-child {
  margin-bottom: 0;
}

.main-footer__menu-link a:hover {
  text-decoration: none;
}

.main-footer__menu-link:not(.main-footer__menu-link--social) a {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size .2s, color .2s;
}

.main-footer__menu-link:not(.main-footer__menu-link--social) a:hover {
  color: #fff;
  background-position: 0 100%;
  background-size: 100% 1px;
}

.main-footer__menu-link--social a:hover span {
  color: #fff;
  background-position: 0 100%;
  background-size: 100% .0625rem;
}

.main-footer__menu-link--social a:hover svg rect {
  fill: #fff;
}

.main-footer__menu-link--social a:hover svg path {
  fill: #017aff;
}

.main-footer__menu-link--social a svg rect, .main-footer__menu-link--social a svg path {
  transition: fill .2s;
}

.main-footer__menu-link--social a span {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size .2s, color .2s;
}

.main-footer__menu-link--social a span:hover {
  background-position: 0 100%;
  background-size: 100% 1px;
}

.btn {
  width: -moz-fit-content;
  width: fit-content;
  height: 3rem;
  border-radius: .625rem;
  justify-content: center;
  align-items: center;
  padding: .8125rem 1.53125rem;
  font-family: Inter, sans-serif;
  font-weight: 400;
  transition: background .2s, color .2s, border-color .2s, opacity .2s;
  display: flex;
}

.btn--arrow {
  color: #fff;
  min-width: 11.5rem;
  background: #017aff;
}

.btn--arrow:hover {
  background: #017affbf;
}

.btn--arrow:hover svg {
  transform: translateX(3px);
}

.btn--arrow svg {
  transition: transform .2s;
}

.btn--arrow span {
  margin-right: .5rem;
}

.btn--white {
  color: #000;
  background: #fff;
  border: .125rem solid #fff;
}

.btn--white:hover {
  color: #fff;
  background: none;
}

.btn--transparent {
  color: #fff;
  border: .125rem solid #fff;
}

.btn--transparent:hover {
  color: #000;
  background: #fff;
}

.btn:hover {
  text-decoration: none;
}

.section__header {
  margin-bottom: 3.25rem;
}

@media (min-width: 992px) {
  .section__header {
    max-width: 47.9375rem;
    margin-bottom: 2.25rem;
  }
}

.section__header.has-text-align-center {
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 3.25rem;
  display: flex;
}

@media (min-width: 992px) {
  .section__header.has-text-align-center {
    margin-bottom: 2.25rem;
  }
}

.section__header.has-color-light .section__description {
  color: #ffffffbf;
}

.section__header.has-color-light .section__subtitle {
  color: #fff;
  margin-bottom: .625rem;
}

@media (min-width: 992px) {
  .section__header.has-color-light .section__subtitle {
    margin-bottom: .3125rem;
  }
}

.section__header.has-color-light .section__title {
  color: #fff;
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .section__header.has-color-light .section__title {
    margin-bottom: .875rem;
  }
}

.section__subtitle {
  color: #00000080;
  margin-bottom: .5rem;
}

@media (min-width: 992px) {
  .section__subtitle {
    max-width: 35.25rem;
    margin-bottom: .75rem;
  }
}

.section__title {
  margin-bottom: 1rem;
}

.has-token .section__title {
  position: relative;
}

.has-token .section__title:before {
  content: "";
  width: 4.0625rem;
  height: 4.0625rem;
  background: url("Token-Spin-268.52cf953e.gif") center / contain no-repeat;
  margin-bottom: .3125rem;
  display: block;
  position: relative;
}

@media (min-width: 992px) {
  .has-token .section__title:before {
    margin: 0;
    position: absolute;
    top: -4.6875rem;
  }

  .section__title {
    margin-bottom: .75rem;
  }
}

.section__description {
  font-size: 1rem;
  line-height: 1.25;
}

.section__description--small {
  margin-top: 2.875rem;
  font-size: .75rem;
  line-height: 14.52px;
  position: relative;
}

.section__description--small p {
  margin-bottom: 1.3125rem;
}

@media (max-width: 992px) {
  .section__description--small--offset {
    text-align: center;
    margin: 0 auto;
    padding: 0 10rem;
  }
}

@media (max-width: 768px) {
  .section__description--small--offset {
    padding: 0 6rem;
  }
}

@media (max-width: 577px) {
  .section__description--small--offset {
    padding: 0 1rem;
  }
}

@media (min-width: 992px) {
  .section__description--small--offset {
    font-size: .75rem;
    line-height: 14.52px;
    position: relative;
    top: -7rem;
  }

  .section__description--small--offset p {
    margin-bottom: 1.3125rem;
    padding-right: 3.25rem;
  }

  .section__description {
    max-width: 35.25rem;
  }
}

.section__description a {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  text-decoration: none;
  transition: background-size .2s, color .2s;
}

.section__description a:hover {
  background-position: 100% 100%;
  background-size: 0% 1px;
  text-decoration: none;
}

.section__description p {
  margin-bottom: 1.3125rem;
}

.section__description p:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .section__description p {
    margin-bottom: 1.25rem;
  }
}

.post-grid {
  max-width: 22.375rem;
  min-height: 26.9375rem;
  flex-direction: column;
  padding: 0 .5rem;
  display: flex;
}

@media (min-width: 992px) {
  .post-grid {
    padding: 0 .75rem;
  }
}

.post-grid__thumb {
  border-radius: .625rem .625rem 0 0;
}

.post-grid__thumb a {
  -webkit-clip-path: content-box;
  clip-path: content-box;
  border-radius: .625rem .625rem 0 0;
  display: block;
  overflow: hidden;
}

.post-grid__thumb a:hover img {
  transform: scale(1.05);
}

.post-grid__thumb a img {
  width: 100%;
  height: 12.8125rem;
  object-fit: cover;
  border-radius: .625rem .625rem 0 0;
  transition: transform .2s;
  display: block;
}

.post-grid__content {
  background: #fff;
  border-radius: 0 0 .625rem .625rem;
  flex-direction: column;
  flex: 1;
  padding: 2rem 1rem;
  display: flex;
}

@media (min-width: 992px) {
  .post-grid__content {
    padding: 1.6875rem 1.5rem 1.875rem;
  }
}

.post-grid__content > :last-child {
  margin-bottom: 0;
}

.post-grid__title {
  color: #000000bf;
  margin-bottom: .9375rem;
  transition: color .2s;
}

@media (min-width: 992px) {
  .post-grid__title {
    margin-bottom: 1.375rem;
  }
}

.post-grid__title:hover {
  color: #000;
}

.post-grid__title a {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0% 1px;
  transition: background-size .2s, color .2s;
}

.post-grid__title a:hover {
  background-position: 0 100%;
  background-size: 100% 1px;
  text-decoration: none;
}

.post-grid__desc {
  color: #00000080;
  flex: 1;
  margin-bottom: .9375rem;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  line-height: 1.25;
}

.post-grid__link {
  color: #00000080;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  transition: color .2s;
  display: flex;
  position: relative;
}

.post-grid__link:hover {
  color: #000;
}

.post-grid__link:hover:after {
  transform: translateX(3px);
}

.post-grid__link:after {
  content: "";
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_129_14530)'%3E%3Cpath d='M9.36325 2.69666L14.6666 7.99999L9.36325 13.3033M1.33325 7.99999H14.6666H1.33325Z' stroke='%23017AFF' stroke-linecap='square'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_129_14530'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  margin-left: .5rem;
  transition: transform .2s;
  display: block;
}

.thematic-grid {
  background: #017aff0d;
  border-radius: .625rem;
  margin-bottom: .9375rem;
  padding: 2.3125rem 1.5625rem;
}

@media (min-width: 768px) {
  .thematic-grid {
    margin: 0;
  }
}

@media (min-width: 992px) {
  .thematic-grid {
    padding: 2.3125rem;
  }
}

.thematic-grid:last-child {
  margin-bottom: 0;
}

.thematic-grid__icon {
  margin-bottom: 1.125rem;
}

@media (min-width: 992px) {
  .thematic-grid__icon {
    margin-bottom: 2rem;
  }
}

.thematic-grid__icon img {
  max-width: 4.6875rem;
  display: block;
}

@media (min-width: 992px) {
  .thematic-grid__icon img {
    max-width: 6.25rem;
  }
}

.thematic-grid__title {
  color: #00000080;
  margin-bottom: 1.75rem;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

@media (min-width: 992px) {
  .thematic-grid__title {
    margin-bottom: 1.375rem;
    font-weight: 700;
  }
}

.thematic-grid__description {
  color: #000000bf;
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  line-height: 1.33333;
}

.form__input, .form__textarea {
  color: #000;
  width: 100%;
  box-sizing: border-box;
  min-height: 3rem;
  background: #fff;
  border: none;
  border-radius: .625rem;
  outline: none;
  padding: .75rem 1rem;
  display: block;
}

.form__input::-webkit-input-placeholder, .form__textarea::-webkit-input-placeholder {
  color: #00000080;
}

.form__input:-moz-placeholder, .form__textarea:-moz-placeholder {
  color: #00000080;
  opacity: 1;
}

.form__input::-moz-placeholder, .form__textarea::-moz-placeholder {
  color: #00000080;
  opacity: 1;
}

.form__input:-ms-input-placeholder, .form__textarea:-ms-input-placeholder {
  color: #00000080;
}

.form__input::placeholder, .form__textarea::placeholder {
  color: #00000080;
}

.form__label {
  margin-bottom: .5rem;
}

.form__row, .form__item {
  margin-bottom: .9375rem;
}

.form__row:last-child, .form__item:last-child {
  margin: 0;
}

@media (min-width: 992px) {
  .form__row, .form__item {
    margin-bottom: 0;
  }

  .form__row {
    flex: 0 100%;
    margin-bottom: 1.25rem;
  }

  .form__item {
    max-width: 50%;
    flex-grow: 0;
    flex-basis: 50%;
    margin-right: 1rem;
  }

  .form__item--textarea {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.form__textarea {
  resize: none;
  min-height: 5.9375rem;
  scrollbar-width: none;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.375;
  overflow-y: auto;
}

.form__textarea::-webkit-scrollbar {
  width: 0;
  display: none;
}

.form button[type="submit"].loading {
  pointer-events: none;
  background-color: #fff;
  position: relative;
}

.form button[type="submit"].loading:before {
  content: "";
  z-index: 5;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg' stroke='%23017AFF'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(1 1)' stroke-width='2'%3E%3Ccircle cx='22' cy='22' r='6' stroke-opacity='0'%3E%3Canimate attributeName='r' begin='1.5s' dur='3s' values='6;22' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='1.5s' dur='3s' values='1;0' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-width' begin='1.5s' dur='3s' values='2;0' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='22' cy='22' r='6' stroke-opacity='0'%3E%3Canimate attributeName='r' begin='3s' dur='3s' values='6;22' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='3s' dur='3s' values='1;0' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-width' begin='3s' dur='3s' values='2;0' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='22' cy='22' r='8'%3E%3Canimate attributeName='r' begin='0s' dur='1.5s' values='6;1;2;3;4;5;6' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.form__button {
  height: 3rem;
  box-sizing: border-box;
  min-width: 10.75rem;
  margin-top: 1.25rem;
  font-family: Inter, sans-serif;
}

@media (min-width: 992px) {
  .form__button {
    margin-top: 1.875rem;
  }

  .form__row {
    display: flex;
  }

  .form__body {
    flex-wrap: wrap;
    display: flex;
  }
}

.form__body--small {
  flex-wrap: nowrap;
  display: flex;
}

.form-status {
  margin-top: .3125rem;
  display: none;
}

.form-status.active {
  display: block;
}

.form-status.status--error {
  color: #ff9494;
}

.form-status.status--success {
  color: #017aff;
}

.form .form__body--small + .form-status {
  color: #fff;
}

.highlighted {
  color: #017aff;
}

@media (min-width: 992px) {
  .highlighted-background {
    background: #017aff0d;
    border-radius: 1.25rem;
    padding: 0 .5rem;
  }
}

p > .highlighted {
  color: #017affbf;
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

.hero {
  background: #154777;
  padding-top: 8rem;
  position: relative;
}

@media (min-width: 992px) {
  .hero {
    min-height: 34.5rem;
    padding-top: 3.75rem;
  }

  .hero.hero--small {
    min-height: 25rem;
    padding-top: 2rem;
  }
}

.hero__container {
  min-height: inherit;
}

@media (min-width: 992px) {
  .hero__container {
    min-height: inherit;
  }

  .hero__row {
    min-height: inherit;
    align-items: flex-end;
  }

  .hero__row.has-video {
    align-items: center;
  }
}

.hero__col--text > :last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .hero__col--text {
    max-width: 43%;
    flex-basis: 43%;
    align-self: center;
    padding-bottom: 2.25rem;
  }
}

.hero__col--img {
  justify-content: flex-end;
  margin-top: 1.875rem;
  display: flex;
}

@media (min-width: 992px) {
  .hero__col--img {
    max-width: 57%;
    flex-basis: 57%;
    margin-top: 0;
    display: block;
  }
}

.hero__col--video {
  margin: 1.875rem 0;
}

@media (min-width: 992px) {
  .hero__col--video {
    max-width: 57%;
    flex-basis: 57%;
    margin: 0;
    display: block;
  }
}

.hero__col--widget_container {
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 0;
  display: flex;
  position: relative;
}

.hero__col--widget {
  display: none;
}

@media (min-width: 992px) {
  .hero__col--widget {
    justify-content: center;
    margin-left: 11rem;
    display: flex;
    position: absolute;
    top: -8.375rem;
    left: 41.625rem;
  }
}

.hero__title {
  color: #b0c9e8;
  margin-bottom: .625rem;
  padding: 0 1rem;
}

.hero__title.h2 {
  color: #fff;
  margin-bottom: 3rem;
  font-size: 3rem;
  line-height: 1.04167;
}

@media (min-width: 992px) {
  .hero__title.h2 {
    font-size: 3.5rem;
    line-height: 1.14286;
  }

  .hero__title {
    margin-bottom: 2.5rem;
  }
}

.hero__subtitle {
  color: #fff;
  margin-bottom: 1.25rem;
  font-size: 1.5625rem;
  line-height: 1.52;
}

.hero__subtitle--big {
  color: #fff;
  margin-bottom: 1.25rem;
  padding: 0 1rem;
  font-size: 2.1875rem;
  line-height: 1.37143;
}

.hero__description {
  color: #ffffffbf;
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .hero__description {
    margin-bottom: 1.875rem;
    font-size: 1.375rem;
    line-height: 1.18182;
  }
}

.hero__highlight_container {
  color: #017aff;
  margin-bottom: 1.25rem;
}

.hero__highlight_container__highlight {
  background: #fffffff2;
  border-radius: 1.25rem;
  margin-bottom: 1.875rem;
  padding: .5rem 1rem;
  font-size: 1.375rem;
  line-height: 1.52;
  box-shadow: 0 0 10px #b0c9e8;
}

.hero__buttons {
  flex-wrap: wrap;
  gap: .625rem;
  display: flex;
}

@media (min-width: 992px) {
  .hero__buttons {
    gap: .9375rem;
  }
}

.hero__buttons .btn {
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding-inline: 1.0625rem;
}

@media (min-width: 992px) {
  .hero__buttons .btn {
    padding-inline: 1.78125rem;
  }
}

.hero__image {
  width: 24.5625rem;
  margin-right: -3.75rem;
}

@media (min-width: 992px) {
  .hero__image {
    width: auto;
    margin-right: 0;
  }

  .hero__image img {
    max-width: 53.5rem;
    margin-left: -.375rem;
  }

  .hero--small .hero__image img {
    margin-left: -.875rem;
  }
}

.hero__video {
  z-index: 0;
  margin-top: 2em;
  position: relative;
}

@media (min-width: 992px) {
  .hero__video {
    margin-top: 0;
  }
}

.hero__video .vimeo--frame {
  z-index: 0;
  padding: 56.21% 0 0;
  position: relative;
}

.hero__video .vimeo--frame iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 20px;
}

.hero__video select {
  display: none;
}

.hero__video .load_video {
  max-width: 150px;
  z-index: 1;
  color: #000;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  text-align: right;
  background-color: #0000;
  border: 0;
  padding: 10px;
  position: absolute;
  top: -42px;
  right: -10px;
}

@media (min-width: 992px) {
  .hero__video .load_video {
    max-width: 100%;
    display: flex;
  }
}

.hero__video .load_video:before {
  content: attr(data-name);
}

.hero__video .load_video li {
  cursor: pointer;
  width: 100%;
  color: #fff;
  background-color: #565656;
  margin-left: 5px;
  padding: 10px 20px;
  display: none;
}

.hero__video .load_video li:hover {
  color: #fff;
  background-color: #017aff;
}

.hero__video .load_video.open {
  top: -52px;
}

.hero__video .load_video.open li {
  display: inline-block;
}

@media (min-width: 992px) {
  .hero__video .load_video.open li {
    display: inline;
  }
}

.hero__video .load_video.open:before {
  content: "";
}

.hero__phone-svg {
  z-index: 1;
  position: absolute;
  top: 16rem;
  right: 15rem;
}

@media (max-width: 1300px) {
  .hero__phone-svg {
    display: none;
  }
}

.hero__tablet-svg {
  position: absolute;
  top: 7.5rem;
  right: 0;
}

@media (max-width: 1300px) {
  .hero__tablet-svg {
    display: none;
  }
}

.hero__coin-gif {
  z-index: 2;
  position: absolute;
  top: 33.5rem;
  right: 24rem;
}

@media (max-width: 1300px) {
  .hero__coin-gif {
    display: none;
  }
}

.hero .hide-on-mobile {
  display: none;
}

@media (min-width: 992px) {
  .hero .hide-on-mobile {
    display: block;
  }
}

.cta {
  background: #154777;
  padding: 5rem 0;
}

.cta .section__header {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .cta .section__header {
    max-width: 33rem;
  }
}

.cta .section__header.has-color-light .section__subtitle {
  color: #ffffff80;
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .cta .section__header.has-color-light .section__subtitle {
    margin-bottom: .625rem;
  }
}

.cta .section__header.has-color-light .section__title {
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .cta .section__header.has-color-light .section__title {
    margin-bottom: 1.375rem;
    font-size: 3.5rem;
    line-height: 1.28571;
  }
}

.cta__buttons {
  flex-wrap: wrap;
  margin-bottom: -1rem;
  display: flex;
}

.cta__buttons .btn {
  min-width: 10rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding-inline: .875rem;
}

@media (min-width: 992px) {
  .cta__buttons .btn {
    min-width: 11.5rem;
    margin-right: 2rem;
    padding-inline: 1.59375rem;
  }
}

.cta__buttons .btn:last-child {
  margin-right: 0;
}

#about {
  scroll-margin-top: 6.875rem;
}

.video {
  background: #017aff0d;
  padding: 3.375rem 0;
}

@media (min-width: 992px) {
  .video {
    padding: 12.375rem 0 9.25rem;
  }
}

.video .section__header {
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .video .section__header {
    margin-bottom: 1.875rem;
  }
}

.video .section__subtitle {
  margin-bottom: .9375rem;
}

@media (min-width: 992px) {
  .video .section__subtitle {
    margin-bottom: .625rem;
  }
}

.video .section__title {
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .video .section__description {
    display: none;
  }
}

.video .section__description a {
  color: #017aff;
}

.video__col--text-mobile {
  display: none;
}

@media (min-width: 992px) {
  .video__col--text-mobile {
    display: block;
  }
}

.video__col--text-mobile .section__header {
  max-width: 100%;
  margin-bottom: 0;
  display: block;
}

.video__col--text-mobile .section__description {
  max-width: 100%;
  display: block;
}

.video__buttons .btn {
  margin-bottom: .625rem;
}

@media (min-width: 992px) {
  .video__buttons .btn {
    margin-bottom: .9375rem;
  }
}

.video__buttons .btn:last-child {
  margin-bottom: 0;
}

.video__item {
  cursor: pointer;
}

.content-columns {
  padding: 4.25rem 0;
}

@media (min-width: 992px) {
  .content-columns {
    padding: 4.5rem 0;
  }
}

.content-columns.is-contact .section__header {
  margin-bottom: 2.125rem;
}

.content-columns.is-contact .section__title {
  gap: 0 .4375rem;
}

@media (min-width: 992px) {
  .content-columns.is-contact .section__title {
    gap: .25rem .6875rem;
  }
}

.content-columns.is-contact .content-columns__col:first-child {
  margin-bottom: 2.625rem;
}

@media (min-width: 992px) {
  .content-columns.is-contact .content-columns__col:first-child {
    margin-bottom: 0;
  }
}

.content-columns.is-contact .content-columns__col:first-child:last-child {
  margin-bottom: 0;
}

.content-columns.is-contact .content-columns__img {
  max-width: 23.4375rem;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .content-columns.is-contact .content-columns__img {
    max-width: 100%;
  }

  .content-columns.is-contact .content-columns__img img {
    max-width: 30.125rem;
    width: 100%;
  }
}

.content-columns .section__header {
  margin-bottom: 2.125rem;
}

@media (min-width: 992px) {
  .content-columns .section__header {
    margin-bottom: 0;
  }
}

.content-columns .section__title {
  flex-wrap: wrap;
  gap: .5rem;
  margin-bottom: 1.5625rem;
  display: flex;
}

@media (min-width: 992px) {
  .content-columns .section__title {
    gap: .25rem;
    margin-bottom: 1.625rem;
  }

  .content-columns .section__title__no_margin {
    margin-bottom: 0;
  }
}

.content-columns .section__description {
  font-size: 1.125rem;
  line-height: 1.88889;
}

.content-columns .section__description p {
  margin-bottom: 2.125rem;
}

.content-columns .section__description p:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .content-columns .section__description p {
    margin-bottom: 1.875rem;
  }

  .content-columns .section__description {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .content-columns__col:first-child {
    padding-right: 1.375rem;
  }

  .content-columns__col:last-child {
    padding-top: .75rem;
    padding-left: .625rem;
  }
}

.content-columns__col:last-child .section__header {
  margin-bottom: 0;
}

.posts-grid {
  background: #017aff0d;
  padding: 4.8125rem 0 7rem;
}

@media (min-width: 992px) {
  .posts-grid {
    padding: 6.875rem 0 5.625rem;
  }
}

.posts-grid .section__header > :last-child {
  margin-bottom: 0;
}

.posts-grid__row {
  scrollbar-width: none;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0 -.5rem;
  display: flex;
  overflow: auto;
}

.posts-grid__row::-webkit-scrollbar {
  width: 0;
  display: none;
}

@media (min-width: 992px) {
  .posts-grid__row {
    justify-content: center;
    margin: 0 -.75rem;
  }
}

.posts-grid__button {
  justify-content: center;
  margin-top: 3.5625rem;
  display: flex;
}

.posts-grid__button .btn {
  min-width: 13rem;
}

@media (min-width: 992px) {
  .posts-grid__button {
    margin-top: 4.75rem;
  }
}

.image-text {
  background: #017aff;
  padding: 5rem 0;
}

@media (min-width: 992px) {
  .image-text {
    padding: 3.4375rem 0;
  }
}

.image-text .section__header {
  max-width: 100%;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .image-text__row {
    align-items: center;
  }
}

.image-text__col {
  margin-bottom: 3.75rem;
}

@media (min-width: 992px) {
  .image-text__col {
    margin-bottom: 0;
  }
}

.image-text__col:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .image-text__col--text {
    max-width: 46.5%;
    flex-basis: 46.5%;
  }

  .has-icon .image-text__col--text {
    max-width: 50%;
    flex-basis: 50%;
  }

  .image-text__col--img {
    max-width: initial;
    flex: 1;
  }
}

@media (min-width: 1440px) {
  .image-text__col--img {
    margin-right: -1.25rem;
  }

  .has-icon .image-text__col--img {
    margin-right: -11.625rem;
  }
}

.image-text__img {
  max-width: 20.5rem;
  margin: 0 auto;
  position: relative;
}

@media (min-width: 992px) {
  .image-text__img {
    max-width: 37.5625rem;
  }

  .has-icon .image-text__img {
    max-width: 36rem;
  }
}

.image-text__img img {
  width: 100%;
  display: block;
}

.image-text__additional-image {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 992px) {
  .image-text__additional-image {
    left: 0;
  }
}

@media (min-width: 1184px) {
  .image-text__additional-image {
    left: -3.625rem;
  }
}

.image-text__additional-image img {
  max-width: 7.0625rem;
}

@media (min-width: 992px) {
  .image-text__additional-image img {
    max-width: 12.5rem;
  }
}

.image-text__buttons {
  flex-wrap: wrap;
  margin-top: 1.6875rem;
  margin-bottom: -.625rem;
  display: flex;
}

@media (min-width: 992px) {
  .image-text__buttons {
    margin-top: 1.875rem;
    margin-bottom: -1.25rem;
  }
}

.image-text__buttons .btn {
  min-width: 8.125rem;
  margin-bottom: .625rem;
  margin-right: .625rem;
}

@media (min-width: 992px) {
  .image-text__buttons .btn {
    min-width: 11.5rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }
}

.image-list {
  padding: 4rem 0;
}

@media (max-width: 992px) {
  .image-list.no--padding {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .image-list {
    padding: 7.8125rem 0;
  }
}

.image-list.primary-bg {
  background: #017aff0d;
}

@media (min-width: 992px) {
  .image-list.image--small {
    padding: 7rem 0 0;
  }
}

.image-list .section__header {
  margin-bottom: 1.875rem;
}

@media (min-width: 992px) {
  .image-list .section__header {
    margin-bottom: 2.5rem;
  }
}

.image-list .section__subtitle {
  margin-bottom: .9375rem;
}

@media (min-width: 992px) {
  .image-list .section__subtitle {
    margin-bottom: .625rem;
  }
}

.image-list .section__title {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .image-list .section__title {
    margin-bottom: 1.25rem;
  }
}

.image-list .section__description__margin {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .image-list .section__description__margin {
    padding: 0 10rem 0 0;
  }

  .image-list .section__description {
    max-width: 35.25rem;
  }
}

.image-list__row--start {
  align-items: start;
}

@media (min-width: 992px) {
  .image-list__row {
    align-items: center;
  }

  .image-list__col--list {
    max-width: 54%;
    flex-basis: 54%;
  }

  .image--small .image-list__col--list {
    max-width: 60%;
    flex-basis: 60%;
  }

  .image-list__col--img {
    max-width: 46%;
    flex-basis: 46%;
  }

  .image--small .image-list__col--img {
    max-width: 40%;
    flex-basis: 40%;
  }

  .image-list__list {
    max-width: 37.75rem;
  }
}

.image-list__list .list-item {
  margin-bottom: 1.5rem;
  padding-right: 1.125rem;
  display: flex;
}

.image-list__list .list-item:last-child {
  margin-bottom: 0;
}

.image-list__list .list-item__icon {
  min-width: 1.9375rem;
  height: 1.9375rem;
  background: #00c0ad40;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 1.1875rem;
  display: flex;
}

.image-list__list .list-item__description {
  color: #000;
  align-items: center;
  display: flex;
}

.image-list__list .list-item__description br {
  display: none;
}

@media (min-width: 992px) {
  .image-list__list .list-item__description br {
    display: block;
  }
}

.image-list__list .list-item__description--light {
  color: #fff;
}

.image-list__buttons {
  flex-wrap: wrap;
  justify-content: center;
  gap: .625rem;
  margin-top: 2.875rem;
  display: flex;
}

@media (min-width: 992px) {
  .image-list__buttons {
    justify-content: flex-start;
    gap: 1.5rem;
    margin-top: 2.5rem;
  }
}

.image-list__buttons .btn {
  padding-inline: .775rem;
}

@media (min-width: 992px) {
  .image-list__buttons .btn {
    padding-inline: 1.2rem;
  }
}

@media (max-width: 992px) {
  .image-list .styled-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.image-list__img {
  max-width: 20.5rem;
  margin: 3.75rem auto 0;
}

@media (min-width: 992px) {
  .image-list__img {
    max-width: 100%;
    margin: 1rem 0 0;
  }

  .image-list__img img {
    max-width: 48.625rem;
  }
}

.image-list__img__styled {
  max-width: 450px;
  max-height: 300px;
  background: #fff;
  border-radius: 2.5rem;
  margin: 2rem 0;
  padding: 1rem;
  box-shadow: 0 32px 48px -8px #0000001a, 0 0 14px -4px #0000000d;
}

@media (max-width: 992px) {
  .image-list__img__styled {
    justify-content: center;
    display: flex;
  }
}

.image-list__img__styled__big {
  min-width: 40rem;
  min-height: 30rem;
  background: #fff;
  border-radius: 2.5rem;
  margin: 4rem 0;
  padding: 2rem;
  box-shadow: 0 32px 48px -8px #0000001a;
}

@media (min-width: 992px) {
  .image-list__img__styled__offset {
    margin: 4rem 0;
    padding: 2rem;
    position: relative;
    top: -7rem;
  }
}

.thematics-grid {
  padding: 4rem 0;
}

@media (min-width: 992px) {
  .thematics-grid {
    padding: 4.6875rem 0 5.5625rem;
  }
}

.thematics-grid .section__header {
  margin-bottom: 0;
}

.thematics-grid .section__subtitle {
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .thematics-grid .section__subtitle {
    margin-bottom: .625rem;
  }
}

.thematics-grid .section__title {
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .thematics-grid .section__description {
    max-width: 36.875rem;
  }
}

.thematics-grid__row {
  margin-top: 2.5rem;
}

@media (min-width: 768px) {
  .thematics-grid__row {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.375rem;
    display: grid;
  }
}

@media (min-width: 992px) {
  .thematics-grid__row {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 4.375rem;
  }
}

.description-image {
  padding: 3rem 0;
}

@media (min-width: 992px) {
  .description-image {
    padding: 6.875rem 0;
  }
}

.description-image .container {
  max-width: 80.75rem;
}

.description-image .section__header {
  margin-bottom: 2.0625rem;
}

@media (min-width: 992px) {
  .description-image .section__header {
    max-width: 57.9375rem;
    margin-bottom: 3.25rem;
  }

  .description-image .section__description {
    max-width: 57.9375rem;
  }
}

.description-image__button {
  justify-content: center;
  margin-top: 2.0625rem;
  display: flex;
}

@media (min-width: 992px) {
  .description-image__button {
    margin-top: 0;
  }

  .description-image__button .btn {
    min-width: 13rem;
  }
}

.process {
  padding: 3rem 0 4.75rem;
}

@media (min-width: 992px) {
  .process {
    padding: 5.0625rem 0;
  }
}

.process .section__title {
  margin-bottom: 1.5625rem;
}

@media (min-width: 992px) {
  .process .section__title {
    flex-wrap: wrap;
    gap: 0 .5rem;
    margin-bottom: 1.875rem;
    display: flex;
  }
}

.process .section__header {
  margin-bottom: 1.5625rem;
}

@media (min-width: 992px) {
  .process .section__header {
    margin-bottom: 1.875rem;
  }

  .process__row {
    margin: 0 -2.5rem;
  }
}

.process__col--text {
  margin-bottom: 2.5rem;
}

@media (min-width: 992px) {
  .process__col--text {
    margin-bottom: 0;
  }

  .process__col {
    padding-inline: 2.5rem;
  }
}

.process__img {
  display: none;
}

@media (min-width: 992px) {
  .process__img {
    margin-top: 5.875rem;
    display: block;
  }
}

.process__img--mobile {
  display: block;
}

@media (min-width: 992px) {
  .process__img--mobile {
    display: none;
  }
}

.process__cards {
  margin-bottom: 4.375rem;
}

@media (min-width: 992px) {
  .process__cards {
    margin-bottom: 0;
  }
}

.process__cards .process-card {
  border-radius: .625rem;
  margin-bottom: 1.25rem;
  padding: 2.5rem;
}

.process__cards .process-card > :last-child, .process__cards .process-card:last-child {
  margin-bottom: 0;
}

.process__cards .process-card--lightgreen {
  background: #e6fffc;
}

.process__cards .process-card--lightblue {
  background: #f2f8ff;
}

.process__cards .process-card__icon {
  max-width: 3.25rem;
  margin-bottom: .9375rem;
}

@media (min-width: 992px) {
  .process__cards .process-card__icon {
    margin-bottom: .625rem;
  }
}

.process__cards .process-card__title {
  margin-bottom: .9375rem;
}

@media (min-width: 992px) {
  .process__cards .process-card__title {
    margin-bottom: .625rem;
    font-size: 2.375rem;
    line-height: 1.34211;
  }
}

.process__cards .process-card__description {
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  line-height: 1.33333;
}

.small-form {
  background: #017aff;
  padding: 5rem 0;
}

.small-form .section__header {
  text-align: left;
  align-items: flex-start;
  margin-bottom: 1.625rem;
}

@media (min-width: 992px) {
  .small-form .section__header {
    text-align: center;
    align-items: center;
    margin-bottom: 2rem;
  }
}

.small-form .section__subtitle {
  margin-bottom: .625rem;
}

@media (min-width: 992px) {
  .small-form .section__subtitle {
    margin-bottom: .75rem !important;
  }

  .small-form .section__title {
    margin-bottom: 1.25rem !important;
  }
}

.small-form .form {
  max-width: 29.25rem;
}

@media (min-width: 992px) {
  .small-form .form {
    margin: 0 auto;
  }
}

.small-form .form__input {
  width: 100%;
  margin-right: .625rem;
}

@media (min-width: 992px) {
  .small-form .form__input {
    margin-right: 1rem;
  }
}

.small-form .form .btn--transparent {
  height: 3rem;
  box-sizing: border-box;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: .8125rem 1.1875rem;
}

@media (min-width: 992px) {
  .small-form .form .btn--transparent {
    padding-inline: 1.6875rem;
  }
}

.team {
  background: #1547770d;
  padding: 2.9375rem 0;
}

@media (min-width: 992px) {
  .team {
    padding: 6.25rem 0;
  }
}

.team .section__header {
  margin-bottom: 0;
}

.team__grid {
  margin-top: 2.5625rem;
}

@media (min-width: 992px) {
  .team__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0 1.5rem;
    margin-top: 4.375rem;
    display: grid;
  }
}

.team__column {
  margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .team__column {
    margin-bottom: 0;
  }
}

.team .team-item {
  background: #fff;
  border-radius: .625rem;
  margin-bottom: 1.25rem;
  padding: 1.6875rem 1.25rem;
}

@media (min-width: 992px) {
  .team .team-item {
    margin-bottom: 1.5rem;
    padding: 3.3125rem 2.5625rem;
    display: flex;
  }
}

.team .team-item:last-child {
  margin-bottom: 0;
}

.team .team-item__img {
  max-width: 5.25rem;
  margin-bottom: 2rem;
}

@media (min-width: 992px) {
  .team .team-item__img {
    min-width: 7.9375rem;
    max-width: 7.9375rem;
    margin-bottom: 0;
    margin-right: 1.375rem;
  }

  .team .team-item__content {
    padding-top: 1.125rem;
  }
}

.team .team-item__title {
  margin-bottom: 1.75rem;
  font-size: 1.8125rem;
  line-height: 1;
}

.team .team-item__description {
  font-size: 1.125rem;
  line-height: 1.33333;
}

.team .team-item__description p {
  margin-bottom: 1.5rem;
}

.team .team-item__description p:last-child {
  margin-bottom: 0;
}

.team .team-item__description p a {
  color: #017aff;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  text-decoration: none;
  transition: background-size .2s, color .2s;
}

.team .team-item__description p a:hover {
  background-position: 100% 100%;
  background-size: 0% 1px;
  text-decoration: none;
}

.partners {
  padding: 3.75rem 0;
}

@media (min-width: 992px) {
  .partners {
    padding: 5.3125rem 0;
  }
}

.partners .section__header {
  margin-bottom: 2.5rem;
}

.partners .section__title {
  margin-bottom: 1.75rem;
}

@media (min-width: 992px) {
  .partners .section__title {
    margin-bottom: 1.25rem;
  }

  .partners .section__subtitle {
    margin-bottom: .125rem;
  }
}

.partners__row {
  flex-direction: column;
  display: flex;
}

@media (min-width: 992px) {
  .partners__row {
    flex-direction: row;
    align-items: flex-start;
  }
}

.partners__col--img {
  order: 2;
  margin-top: 3.75rem;
}

@media (min-width: 992px) {
  .partners__col--img {
    order: -1;
    margin-top: .625rem;
  }

  .partners__col--partners {
    padding-left: 3.3125rem;
  }
}

.partners__img {
  max-width: 20.5rem;
  margin: 0 auto;
}

@media (min-width: 992px) {
  .partners__img {
    max-width: 100%;
    margin-left: -13.75rem;
  }

  .partners__img img {
    max-width: 44.5rem;
  }
}

.partners .partners-grid {
  grid-template-columns: 1fr;
  gap: 1.5rem;
  display: grid;
}

@media (min-width: 768px) {
  .partners .partners-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: .9375rem 1.5625rem;
  }
}

.partners .partners-grid .partner-grid {
  min-height: 7.5rem;
  width: 100%;
  border: .3125rem solid #e5e5e580;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  display: flex;
}

@media (min-width: 992px) {
  .partners .partners-grid .partner-grid {
    min-height: 6.25rem;
  }
}

.partners .partners-grid .partner-grid img {
  object-fit: contain;
  display: block;
}

.contact {
  padding: 5rem 0;
}

@media (min-width: 992px) {
  .contact {
    padding: 6.875rem 0;
  }
}

.contact .section__header {
  margin-bottom: 3.375rem;
}

@media (min-width: 992px) {
  .contact .section__header {
    max-width: 31.25rem;
  }
}

.contact .section__subtitle, .contact .section__title {
  margin-bottom: 1rem;
}

.contact .form {
  background: #f2f8ff;
  border-radius: .625rem;
  padding: 2.1875rem 1.25rem;
}

@media (min-width: 992px) {
  .contact .form {
    padding: 2.5rem 1.5rem;
  }
}

.contact .form__input, .contact .form__textarea {
  border: .0625rem solid #0000001a;
}

.information-grid {
  background: #017aff0d;
  padding: 3.125rem 0 5.25rem;
}

@media (min-width: 992px) {
  .information-grid {
    padding: 5.875rem 0;
  }
}

.information-grid .section__header {
  margin-bottom: 2.875rem;
}

@media (min-width: 992px) {
  .information-grid .section__header {
    margin-bottom: 4.375rem;
  }

  .information-grid .section__subtitle {
    margin-bottom: 1.125rem;
  }

  .information-grid .section__title {
    margin-bottom: 1.5rem;
  }

  .information-grid__row {
    grid-template-columns: repeat(2, 1fr);
    gap: 3.25rem;
    display: grid;
  }
}

.information-grid__row .information-item {
  height: 100%;
  background: #fff;
  border-radius: .625rem;
  margin-bottom: 1.25rem;
  padding: 2.375rem 2.5rem;
}

.information-grid__row .information-item:last-child, .information-grid__row .information-item > :last-child {
  margin-bottom: 0;
}

.information-grid__row .information-item__title {
  color: #017aff;
  margin-bottom: 1.8125rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3125;
}

.information-grid__row .information-item__description {
  color: #00000080;
  margin-bottom: 1.4375rem;
  font-size: 1.125rem;
  line-height: 1.55556;
}

.information-grid__row .information-item__contacts {
  margin-bottom: 1.125rem;
}

.information-grid__row .information-item__contact {
  color: #000;
  align-items: center;
  margin-bottom: .625rem;
  font-size: 1.125rem;
  line-height: 1.22222;
  display: flex;
}

.information-grid__row .information-item__contact:last-child {
  margin-bottom: 0;
}

.information-grid__row .information-item__contact img {
  margin-right: .625rem;
}

.information-grid__row .information-item__contact a {
  color: #017aff;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  text-decoration: none;
  transition: background-size .2s, color .2s;
}

.information-grid__row .information-item__contact a:hover {
  background-position: 100% 100%;
  background-size: 0% 1px;
  text-decoration: none;
}

.not-found {
  text-align: center;
  padding: 6.25rem 0;
}

@media (min-width: 992px) {
  .not-found {
    min-height: calc(100vh - 558px);
  }
}

.not-found .container > * {
  margin-bottom: 1.25rem;
}

.not-found .container > :last-child {
  margin-bottom: 0;
}

.not-found .container a {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  text-decoration: none;
  transition: background-size .2s, color .2s;
}

.not-found .container a:hover {
  background-position: 100% 100%;
  background-size: 0% 1px;
  text-decoration: none;
}

.fund {
  background: #154777;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  padding-top: 8rem;
  padding-bottom: 3.75rem;
  font-weight: 700;
  display: flex;
}

@media (min-width: 992px) {
  .fund {
    min-height: 34.5rem;
    padding-top: 13.125rem;
    padding-bottom: 13.125rem;
  }
}

.fund h1 {
  color: #fff;
  font-size: 85px;
}

@media (min-width: 992px) {
  .fund h1 {
    font-size: 92px;
  }
}

.fund h3 {
  color: #fff;
  text-align: center;
  padding: 1rem;
  font-size: 24px;
  line-height: 25.6px;
}

@media (min-width: 992px) {
  .fund h3 {
    font-size: 21px;
  }
}

.fund button {
  color: #000;
  cursor: pointer;
  background: #ffd600;
  border: none;
  border-radius: 12px;
  margin-top: 2rem;
  padding: 12px 20px;
  font-size: 22px;
}

@media (min-width: 992px) {
  .fund button {
    font-size: 15px;
  }
}

.faq {
  background-color: #224774;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 1rem;
  display: flex;
}

@media (min-width: 768px) {
  .faq {
    padding-top: 9.25rem;
    padding-bottom: 9.25rem;
  }
}

.faq h1 {
  text-align: center;
  color: #fff;
  font-size: 21px;
  font-weight: 700;
}

.faq .questions-container {
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 3.25rem;
  display: flex;
}

@media (max-width: 992px) {
  .faq .questions-container {
    justify-content: flex-start;
  }
}

.faq .questions-container div {
  min-width: 62.5rem;
}

@media (max-width: 992px) {
  .faq .questions-container div {
    padding: 0 7.75rem;
  }
}

@media (max-width: 768px) {
  .faq .questions-container div {
    padding: 0 14.25rem;
  }
}

@media (max-width: 577px) {
  .faq .questions-container div {
    padding: 0 20rem;
  }
}

.faq .questions-container div > details {
  border-bottom: 1px solid #fff3;
  padding: 1rem;
}

.faq .questions-container div > details > summary {
  color: #fff;
  font-size: .75rem;
  font-weight: 700;
  list-style: none;
}

.faq .questions-container .icon {
  margin-right: 10px;
  transition: transform .3s;
}

.faq .questions-container div > details[open] {
  border-bottom: none;
}

.faq .questions-container div > details[open] .icon {
  transform: rotate(-90deg);
}

.faq .questions-container div > details[open] > p {
  border-bottom: 1px solid #fff3;
}

.faq .questions-container div > details {
  width: 100%;
}

.faq .questions-container div > details > p {
  color: #fff;
  margin-top: 1.5rem;
  padding: 0 2rem 2rem 1.5rem;
  font-size: .75rem;
  font-weight: 400;
}

.faq .questions-container hr {
  border: 1px solid #fff;
  margin-top: 1.5rem;
}

.steps {
  background-color: #143255;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 1rem;
  display: flex;
}

@media (min-width: 768px) {
  .steps {
    padding-top: 9.25rem;
    padding-bottom: 9.25rem;
  }
}

.steps h1 {
  text-align: center;
  color: #fff;
  font-size: 42px;
  font-weight: 700;
}

.steps h3 {
  color: #b4cbd8;
  text-align: center;
  margin-top: 8.25rem;
  padding: 0 21.5625rem;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 992px) {
  .steps h3 {
    padding: 0 1.25rem;
  }
}

.steps h3 span > img {
  vertical-align: text-top;
}

.steps .steps-container {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  margin-top: 8.25rem;
  display: flex;
}

@media (max-width: 992px) {
  .steps .steps-container {
    flex-direction: column;
    margin-top: 3.5rem;
  }
}

.steps .steps-container .step {
  width: 13.625rem;
}

@media (min-width: 992px) {
  .steps .steps-container .step {
    width: unset;
  }
}

.steps .steps-container .step .step__description {
  margin-top: 1rem;
}

.steps .steps-container .step .step__description span {
  color: #fff;
  font-size: 21px;
  font-weight: 700;
}

.steps .steps-container .step .step__description p {
  color: #fff;
  margin-top: 1.5rem;
  font-size: 16px;
  font-weight: 400;
}

.steps .steps-availability {
  width: 100%;
  max-width: 800px;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 8.25rem;
  display: flex;
}

@media (max-width: 992px) {
  .steps .steps-availability {
    margin-top: 3.5rem;
  }
}

.steps .steps-availability p {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.steps .steps-availability div {
  justify-content: center;
  gap: 1.75rem;
  display: flex;
}

@media (max-width: 992px) {
  .steps .steps-availability div {
    flex-direction: column;
  }
}

.strategy {
  background-color: #f4f8ff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.6875rem 1rem;
  display: flex;
}

.strategy h1 {
  text-align: center;
  color: #224774;
  margin: 0 0 1.125rem;
  font-size: 42px;
}

@media (max-width: 992px) {
  .strategy h1 {
    margin: 0 0 2.6875rem;
  }
}

.strategy h3 {
  color: #224774;
  margin: 2.1875rem 0 .5rem;
  font-size: 24px;
}

.strategy .hidden {
  display: none;
}

.strategy .selected {
  color: #224774;
  text-decoration: underline;
}

.strategy .arrow-container {
  width: 94%;
  display: none;
}

.strategy .arrow-container > div {
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.strategy .tabs-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.strategy .tabs-container > h2 {
  color: #2e6ebb;
  min-width: 14.3125rem;
  text-align: center;
  font-size: 24px;
}

@media (max-width: 992px) {
  .strategy .tabs-container > h2 {
    min-width: 9.375rem;
  }
}

.strategy .cards-container {
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
  margin-top: 4.375rem;
  display: flex;
}

@media (max-width: 992px) {
  .strategy .cards-container {
    flex-direction: column;
  }
}

.strategy .card {
  max-width: 22.625rem;
}

.strategy .card > h2 {
  color: #224774;
  margin-top: 1.375rem;
  font-size: 24px;
}

.strategy .card > p {
  margin-top: .5rem;
}

.strategy .card > img {
  border-radius: 20px;
}

/*# sourceMappingURL=index.89761332.css.map */
