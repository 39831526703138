.team {
	padding: rem(47) 0;
	background: rgba($color--3, 0.05);

	@media (min-width: $lg) {
		padding: rem(100) 0;
	}

	.section__header {
		margin-bottom: 0;
	}

	&__grid {
		margin-top: rem(41);

		@media (min-width: $lg) {
			margin-top: rem(70);
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 0 rem(24);
		}
	}

	&__column {
		margin-bottom: rem(20);

		@media (min-width: $lg) {
			margin-bottom: 0;
		}
	}

	.team-item {
		border-radius: rem(10);
		background: $color--light;
		padding: rem(27) rem(20);
		margin-bottom: rem(20);

		@media (min-width: $lg) {
			margin-bottom: rem(24);
			padding: rem(53) rem(41);
			display: flex;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&__img {
			max-width: rem(84);
			margin-bottom: rem(32);

			@media (min-width: $lg) {
				min-width: rem(127);
				max-width: rem(127);
				margin-right: rem(22);
				margin-bottom: 0;
			}
		}

		&__content {
			@media (min-width: $lg) {
				padding-top: rem(18);
			}
		}

		&__title {
			@include font(29, 29);
			margin-bottom: rem(28);
		}

		&__description {
			@include font(18, 24);

			p {
				margin-bottom: rem(24);

				&:last-child {
					margin-bottom: 0;
				}


				a {
					@include link-underline();
					color: $color--primary;
				}
			}


		}
	}
}