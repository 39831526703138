.cta {
	padding: rem(80) 0;
	background: $color--3;

	.section {
		&__header {
			margin-bottom: rem(32);

			@media (min-width: $lg) {
				max-width: rem(528);
			}

			&.has-color-light {
				.section {
					&__subtitle {
						margin-bottom: rem(16);
						color: rgba($color--light, 0.5);

						@media (min-width: $lg) {
							margin-bottom: rem(10);
						}
					}

					&__title {
						margin-bottom: rem(32);

						@media (min-width: $lg) {
							@include font(56, 72);
							margin-bottom: rem(22);
						}
					}
				}
			}
		}
	}

	&__buttons {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: rem(-16);
		.btn {
			margin-right: rem(16);
			padding-inline: rem(14);
			margin-bottom: rem(16);
			min-width: rem(160);

			@media (min-width: $lg) {
				min-width: rem(184);
				padding-inline: rem(25.5);
				margin-right: rem(32);
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}