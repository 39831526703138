.btn {
	font-family: $font-secondary;
	font-weight: 400;
	padding: rem(13) rem(24.5);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: rem(10);
	width: fit-content;
	height: rem(48);

	transition: background $default-transition-speed ease,
				color $default-transition-speed ease,
				border-color $default-transition-speed ease,
				opacity $default-transition-speed ease;



	&--arrow {
		background: $color--primary;
		color: $color--light;

		min-width: rem(184);

		&:hover {
			background: rgba($color--primary, 0.75);

			svg {
				transform: translateX(3px);
			}
		}

		svg {
			transition: transform $default-transition-speed;
		}

		span {
			margin-right: rem(8);
		}
	}

	&--white {
		background: $color--light;
		border: rem(2) solid $color--light;
		color: $color--dark;

		&:hover {
			background: transparent;
			color: $color--light;
		}
	}

	&--transparent {
		color: $color--light;
		border: rem(2) solid $color--light;

		&:hover {
			background: $color--light;
			color: $color--dark;
		}
	}

	&:hover {
		text-decoration: none;
	}
}