.strategy {
  background-color: #f4f8ff;

  padding: rem(43) 1rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  h1 {
    text-align: center;
    font-size: 42px;
    color: #224774;
    margin: 0 0 rem(18) 0;

    @media (max-width: $lg) {
      margin: 0 0 rem(43) 0;
    }
  }

  h3 {
    font-size: 24px;
    color: #224774;
    margin: rem(35) 0 rem(8) 0;
  }

  .hidden {
    display: none;
  }

  .selected {
    color: #224774;
    text-decoration: underline;
  }

  .arrow-container {
    display: none; // TODO: Remove this line
    width: 94%;
  }

  .arrow-container > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

  .tabs-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabs-container > h2 {
    font-size: 24px;
    color: #2e6ebb;
    min-width: rem(229);
    // cursor: pointer;
    @media (max-width: $lg) {
      min-width: rem(150);
    }
    text-align: center;
  }

  .cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(30);
    margin-top: rem(70);

    @media (max-width: $lg) {
      flex-direction: column;
    }
  }

  .card {
    max-width: rem(362);
  }

  .card > h2 {
    font-size: 24px;
    color: #224774;
    margin-top: rem(22);
  }

  .card > p {
    margin-top: rem(8);
  }

  .card > img {
    border-radius: 20px;
  }
}
