.form {
	&__input, &__textarea {
		background: $color--light;
		border-radius: rem(10);
		padding: rem(12) rem(16);
		color: $color--dark;
		width: 100%;
		box-sizing: border-box;
		border: none;
		outline: none;
		display: block;
		min-height: rem(48);

		&::-webkit-input-placeholder {
			color: rgba($color--dark, 0.5);
		}
		&:-moz-placeholder {
			color: rgba($color--dark, 0.5);
			opacity:  1;
		}
		&::-moz-placeholder {
			color: rgba($color--dark, 0.5);
			opacity:  1;
		}
		&:-ms-input-placeholder {
			color: rgba($color--dark, 0.5);
		}
		&::-ms-input-placeholder {
			color: rgba($color--dark, 0.5);
		}
		&::placeholder {
			color: rgba($color--dark, 0.5);
		}
	}

	&__label {
		margin-bottom: rem(8);
	}

	&__row, &__item {
		margin-bottom: rem(15);
		&:last-child {
			margin: 0;
		}

		@media (min-width: $lg) {
			margin-bottom: 0;
		}
	}

	&__row {
		@media (min-width: $lg) {
			flex: 0 1 100%;
			margin-bottom: rem(20);
		}
	}

	&__item {
		@media (min-width: $lg) {
			max-width: 50%;
			flex-basis: 50%;
			flex-grow: 0;
			margin-right: rem(16);
		}

		&--textarea {
			@media (min-width: $lg) {
				max-width: 100%;
				flex-basis: 100%;
			}
		}
	}

	&__textarea {
		resize: none;
		min-height: rem(95);
		@include scrollbar-hidden();
		width: 100%;
		padding: rem(16);
		@include font(16, 22);
	}

	button[type="submit"] {
		&.loading {
			pointer-events: none;
			position: relative;
			background-color: $color--light;

			&::before {
				content: '';
				position: absolute;
				z-index: 5;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 45 45' xmlns='http://www.w3.org/2000/svg' stroke='%23017AFF'%3E%3Cg fill='none' fill-rule='evenodd' transform='translate(1 1)' stroke-width='2'%3E%3Ccircle cx='22' cy='22' r='6' stroke-opacity='0'%3E%3Canimate attributeName='r' begin='1.5s' dur='3s' values='6;22' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='1.5s' dur='3s' values='1;0' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-width' begin='1.5s' dur='3s' values='2;0' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='22' cy='22' r='6' stroke-opacity='0'%3E%3Canimate attributeName='r' begin='3s' dur='3s' values='6;22' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='3s' dur='3s' values='1;0' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-width' begin='3s' dur='3s' values='2;0' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='22' cy='22' r='8'%3E%3Canimate attributeName='r' begin='0s' dur='1.5s' values='6;1;2;3;4;5;6' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
	}

	&__button {
		margin-top: rem(20);
		height: rem(48);
		box-sizing: border-box;
		min-width: rem(172);
		font-family: $font-secondary;

		@media (min-width: $lg) {
			margin-top: rem(30);
		}
	}

	&__row {
		@media (min-width: $lg) {
			display: flex;
		}
	}

	&__body {
		@media (min-width: $lg) {
			display: flex;
			flex-wrap: wrap;
		}

		&--small {
			display: flex;
			flex-wrap: nowrap;
		}
	}

	&-status {
		display: none;
		margin-top: rem(5);
		// color: $color--error;

		&.active {
			display: block;
		}

		&.status--error {
			color: $color--error;
		}

		&.status--success {
			color: $color--primary;
		}
	}

	.form__body--small {
		& + .form-status {
			color: $color--light;
		}
	}
}