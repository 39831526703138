.steps {
  background-color: rgba(20, 50, 85, 1);
  @media (min-width: 768px) {
    padding-top: rem(148);
    padding-bottom: rem(148);
  }
  padding: 5rem 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 42px;
    color: white;
  }

  h3 {
    font-weight: 400;
    font-size: 16px;
    color: #b4cbd8;
    margin-top: rem(132);
    text-align: center;
    padding: 0 rem(345);

    @media (max-width: $lg) {
      padding: 0 rem(20);
    }

    span > img {
      vertical-align: text-top;
    }
  }

  .steps-container {
    display: flex;
    @media (max-width: $lg) {
      margin-top: rem(56);
      flex-direction: column;
    }
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6rem;
    margin-top: rem(132);
    width: 100%;

    .step {
      width: 13.625rem;
      @media (min-width: $lg) {
        width: unset;
      }
      .step__description {
        margin-top: rem(16);
        span {
          color: white;
          font-size: 21px;
          font-weight: 700;
        }
        p {
          color: white;
          font-size: 16px;
          font-weight: 400;
          margin-top: rem(24);
        }
      }
    }
  }

  .steps-availability {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: rem(132);
    width: 100%;
    max-width: 800px;

    @media (max-width: $lg) {
      margin-top: rem(56);
    }

    p {
      color: white;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
    }

    div {
      display: flex;
      justify-content: center;
      gap: rem(28);

      @media (max-width: $lg) {
        flex-direction: column;
      }
    }
  }
}
