.information-grid {
	padding: rem(50) 0 rem(84) 0;
	background: rgba($color--primary, 0.05);

	@media (min-width: $lg) {
		padding: rem(94) 0;
	}

	.section {
		&__header {
			margin-bottom: rem(46);

			@media (min-width: $lg) {
				margin-bottom: rem(70);
			}
		}

		&__subtitle {
			@media (min-width: $lg) {
				margin-bottom: rem(18);
			}
		}

		&__title {
			@media (min-width: $lg) {
				margin-bottom: rem(24);
			}
		}
	}

	&__row {

		@media (min-width: $lg) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: rem(52);
		}

		.information-item {
			padding: rem(38) rem(40);
			border-radius: rem(10);
			background: $color--light;
			margin-bottom: rem(20);
			height: 100%;

			&:last-child {
				margin-bottom: 0;
			}

			& > *:last-child {
				margin-bottom: 0;
			}


			&__title {
				@include font(32, 42);
				color: $color--primary;
				margin-bottom: rem(29);
				font-weight: 700;
			}

			&__description {
				color: rgba($color--dark, 0.5);
				@include font(18, 28);
				margin-bottom: rem(23);
			}

			&__contacts {
				margin-bottom: rem(18);
			}

			&__contact {
				display: flex;
				align-items: center;
				color: $color--dark;
				@include font(18, 22);
				margin-bottom: rem(10);

				&:last-child {
					margin-bottom: 0;
				}

				img {
					margin-right: rem(10);
				}

				a {
					color: $color--primary;
					@include link-underline();
				}
			}

		}
	}
}