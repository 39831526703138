.partners {
	padding: rem(60) 0;

	@media (min-width: $lg) {
		padding: rem(85) 0;
	}


	.section {
		&__header {
			margin-bottom: rem(40);
		}

		&__title {
			margin-bottom: rem(28);

			@media (min-width: $lg) {
				margin-bottom: rem(20);
			}
		}

		&__subtitle {
			@media (min-width: $lg) {
				margin-bottom: rem(2);
			}
		}
	}

	&__row {
		display: flex;
		flex-direction: column;

		@media (min-width: $lg) {
			flex-direction: row;
			align-items: flex-start;
		}
	}

	&__col {
		&--img {
			order: 2;
			margin-top: rem(60);

			@media (min-width: $lg) {
				margin-top: rem(10);
				order: -1;
			}
		}

		&--partners {
			@media (min-width: $lg) {
				padding-left: rem(53);
			}
		}
	}

	&__img {
		max-width: rem(328);
		margin: 0 auto;

		@media (min-width: $lg) {
			max-width: 100%;
			margin-left: rem(-220);
		}

		img {
			@media (min-width: $lg) {
				max-width: rem(712);
			}
		}
	}

	.partners-grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: rem(24);

		@media (min-width: $md) {
			grid-template-columns: repeat(2, 1fr);
			gap: rem(15) rem(25);
		}

		.partner-grid {
			min-height: rem(120);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			border: rem(5) solid rgba(229, 229, 229, 0.5);
			padding: 0 rem(24);

			@media (min-width: $lg) {
				min-height: rem(100);
			}

			img {
				display: block;
				object-fit: contain;
			}
		}
	}
}