.thematic-grid {
	background: rgba($color--primary, 0.05);
	border-radius: rem(10);
	margin-bottom: rem(15);
	padding: rem(37) rem(25);

	@media (min-width: $md) {
		margin: 0;
	}

	@media (min-width: $lg) {
		padding: rem(37);
	}

	&:last-child {
		margin-bottom: 0;
	}

	&__icon {
		margin-bottom: rem(18);

		@media (min-width: $lg) {
			margin-bottom: rem(32);
		}

		img {
			max-width: rem(75);
			display: block;

			@media (min-width: $lg) {
				max-width: rem(100);
			}
		}
	}

	&__title {
		@include font(16, 20);
		font-weight: 400;
		color: rgba($color--dark, 0.5);
		font-family: $font-secondary;
		margin-bottom: rem(28);

		@media (min-width: $lg) {
			font-weight: 700;
			margin-bottom: rem(22);
		}
	}

	&__description {
		color: rgba($color--dark, 0.75);
		font-family: $font-secondary;
		@include font(18, 24);
	}
}