.post-grid {
  max-width: rem(358);
  min-height: rem(431);
  display: flex;
  flex-direction: column;
  padding: 0 rem(8);

  @media (min-width: $lg) {
    padding: 0 rem(12);
    // max-width: 33.3333%;
  }

  &__thumb {
    border-radius: rem(10) rem(10) 0 0;
    a {
      overflow: hidden;
      clip-path: content-box;
      border-radius: rem(10) rem(10) 0 0;
      display: block;
      &:hover {
        img {
          transform: scale(1.05);
        }
      }

      img {
        width: 100%;
        display: block;
        height: rem(205);
        object-fit: cover;
        border-radius: rem(10) rem(10) 0 0;
        transition: transform $default-transition-speed;
      }
    }
  }

  &__content {
    background: $color--light;
    padding: rem(32) rem(16) rem(32);
    border-radius: 0 0 rem(10) rem(10);
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (min-width: $lg) {
      padding: rem(27) rem(24) rem(30);
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
  &__title {
    color: rgba($color--dark, 0.75);
    margin-bottom: rem(15);
    transition: color $default-transition-speed;

    @media (min-width: $lg) {
      margin-bottom: rem(22);
    }

    &:hover {
      color: $color--dark;
    }

    a {
      @include link();

      &:hover {
        text-decoration: none;
      }
    }
  }
  &__desc {
    @include font(16, 20);
    margin-bottom: rem(15);
    color: rgba($color--dark, 0.5);
    font-family: $font-secondary;
    flex: 1;
  }
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    color: rgba($color--dark, 0.5);
    transition: color $default-transition-speed;
    width: fit-content;

    &:hover {
      color: $color--dark;

      &::after {
        transform: translateX(3px);
      }
    }

    &::after {
      content: "";
      display: block;
      margin-left: rem(8);
      width: rem(16);
      height: rem(16);
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_129_14530)'%3E%3Cpath d='M9.36325 2.69666L14.6666 7.99999L9.36325 13.3033M1.33325 7.99999H14.6666H1.33325Z' stroke='%23017AFF' stroke-linecap='square'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_129_14530'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      transition: transform $default-transition-speed;
    }
  }
}
