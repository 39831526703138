/**
 * Convert px to rem.
 */

@function rem($sizeValue: 16) {
	@return calc($sizeValue / 16)+rem;
}

/**
 * Font-size and Line-height.
 */

@mixin line-height($font-size, $line-height: 0) {
	@if $line-height != 0 {
		@if $line-height < 10 {
			line-height: $line-height;
		}
		@else {
			line-height: calc($line-height / $font-size);
		}
	}
}

@mixin letter-spacing($font-size, $letter-spacing: 0) {
	@if $letter-spacing != 0 {
		@if $letter-spacing < 10 {
			letter-spacing: rem($letter-spacing);
		}
		@else {
			letter-spacing: rem(($font-size * $letter-spacing) / 1000);
		}
	}
}

@mixin font($font-size-map, $line-height: 0, $letter-spacing: 0) {
	@if type-of($font-size-map) == 'map' {
		@each $breakpoint, $font-size in $font-size-map {
			@if $breakpoint == null {
				font-size: rem($font-size);

				@include line-height(map-get($font-size-map, nth(map-keys($font-size-map), -1)), $line-height);
				@include letter-spacing(map-get($font-size-map, nth(map-keys($font-size-map), -1)), $letter-spacing);
			}
			@else {
				@media screen and (min-width: map-get($breakpoints, $breakpoint)) {
					font-size: rem($font-size);
				}
			}
		}
	}
	@else {
		font-size: rem($font-size-map);

		@include line-height($font-size-map, $line-height);
		@include letter-spacing($font-size-map, $letter-spacing);
	}
}

@mixin link($height: 1) {
	transition: background-size $default-transition-speed ease 0s, color $default-transition-speed ease;
	background-image: linear-gradient(currentColor, currentColor);
	background-repeat: no-repeat;
	background-position: 100% 100%;
	background-size: 0% #{$height}px;

	&:hover {
		background-size: 100% #{$height}px;
		background-position: 0 100%;
	}
}

@mixin link-underline($height: 1) {
	transition: background-size $default-transition-speed ease 0s, color $default-transition-speed ease;
	background-image: linear-gradient(currentColor, currentColor);
	background-repeat: no-repeat;
	background-position: 0 100%;
	background-size: 100% #{$height}px;
	text-decoration: none;

	&:hover {
		text-decoration: none;
		background-size: 0% #{$height}px;
		background-position: 100% 100%;
	}
}

@mixin scrollbar($color-scrollbar, $color-scrollbar-bg) {
	overflow-y: auto;

	scrollbar-width: thin;
	scrollbar-color: $color-scrollbar $color-scrollbar-bg;

	&::-webkit-scrollbar {
		width: rem(8);
	}
	&::-webkit-scrollbar-track {
		background: $color-scrollbar-bg;
	}
	&::-webkit-scrollbar-thumb {
		border: 0;
		border-radius: 0;
		background-color: $color-scrollbar;
	}
}

@mixin scrollbar-hidden() {
	overflow-y: auto;

	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0;
		display: none;
	}
}