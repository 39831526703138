.thematics-grid {
	padding: rem(64) 0;

	@media (min-width: $lg) {
		padding: rem(75) 0 rem(89) 0;
	}

	.section {
		&__header {
			margin-bottom: 0;
		}

		&__subtitle {
			margin-bottom: rem(16);

			@media (min-width: $lg) {
				margin-bottom: rem(10);
			}
		}

		&__title {
			margin-bottom: rem(20);
		}

		&__description {
			@media (min-width: $lg) {
				max-width: rem(590);
			}
		}
	}

	&__row {
		margin-top: rem(40);

		@media (min-width: $md) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: rem(22);
		}

		@media (min-width: $lg) {
			grid-template-columns: repeat(3, 1fr);
		}


		@media (min-width: $lg) {
			margin-top: rem(70);

		}
	}
}