.small-form {
	padding: rem(80) 0;
	background: $color--primary;


	.section {
		&__header {
			align-items: flex-start;
			text-align: left;
			margin-bottom: rem(26);

			@media (min-width: $lg) {
				align-items: center;
				text-align: center;
				margin-bottom: rem(32);
			}
		}
		&__subtitle {
			margin-bottom: rem(10);

			@media (min-width: $lg) {
				margin-bottom: rem(12) !important;
			}
		}

		&__title {
			@media (min-width: $lg) {
				margin-bottom: rem(20) !important;
			}
		}
	}
	.form {
		max-width: rem(468);

		@media (min-width: $lg) {
			margin: 0 auto;
		}

		&__input {
			width: 100%;
			margin-right: rem(10);

			@media (min-width: $lg) {
				margin-right: rem(16);
			}
		}

		.btn--transparent {
			height: rem(48);
			box-sizing: border-box;
			padding: rem(13) rem(19);
			min-width: fit-content;

			@media (min-width: $lg) {
				padding-inline: rem(27);
			}
		}
	}
}