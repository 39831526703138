@font-face {
	font-family: 'SF Pro Display';
	src: url('../fonts/SF-Pro-Display-Bold.woff2') format('woff2'),
		 url('../fonts/SF-Pro-Display-Bold.woff2') format('woff');
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('../fonts/SF-Pro-Display-Semibold.woff2') format('woff2'),
		 url('../fonts/SF-Pro-Display-Semibold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('../fonts/SF-Pro-Display-Regular.woff2') format('woff2'),
		 url('../fonts/SF-Pro-Display-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display';
	src: url('../fonts/SF-Pro-Display-Light.woff2') format('woff2'),
		 url('../fonts/SF-Pro-Display-Light.woff') format('woff');
	font-weight: 300;
	font-display: swap;
}