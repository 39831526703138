.section {
  &__header {
    margin-bottom: rem(52);

    @media (min-width: $lg) {
      margin-bottom: rem(36);
      max-width: rem(767);
    }

    &.has-text-align-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      margin-bottom: rem(52);

      @media (min-width: $lg) {
        margin-bottom: rem(36);
      }
    }

    &.has-color-light {
      .section {
        &__description {
          color: rgba($color--light, 0.75);
        }
        &__subtitle {
          color: $color--light;
          margin-bottom: rem(10);

          @media (min-width: $lg) {
            margin-bottom: rem(5);
          }
        }
        &__title {
          color: $color--light;
          margin-bottom: rem(20);

          @media (min-width: $lg) {
            margin-bottom: rem(14);
          }
        }
      }
    }
  }

  &__subtitle {
    color: rgba($color--dark, 0.5);
    margin-bottom: rem(8);

    @media (min-width: $lg) {
      max-width: rem(564);
      margin-bottom: rem(12);
    }
  }

  &__title {
    margin-bottom: rem(16);

    .has-token & {
      position: relative;

      &::before {
        content: "";
        background: url("../img/Token-Spin-268.gif") center / contain no-repeat;
        display: block;
        position: absolute;
        width: rem(65);
        height: rem(65);
        position: relative;
        margin-bottom: rem(5);

        @media (min-width: $lg) {
          position: absolute;
          top: rem(-75);
          margin: 0;
        }
      }
    }

    @media (min-width: $lg) {
      margin-bottom: rem(12);
    }
  }

  &__description {
    @include font(16, 20);

    &--small {
      @include font(12, 20);
      position: relative;
      margin-top: 2.875rem;
      line-height: 14.52px;
      p {
        margin-bottom: rem(21);
      }

      &--offset {
        @media (max-width: $lg) {
          text-align: center;
          margin: 0 auto;
          padding: 0 10rem;
        }
        @media (max-width: $md) {
          padding: 0 6rem;
        }
        @media (max-width: $sm) {
          padding: 0 1rem;
        }
        @media (min-width: $lg) {
          @include font(12, 20);
          position: relative;
          top: -7rem;
          line-height: 14.52px;
          p {
            margin-bottom: rem(21);
            padding-right: 3.25rem;
          }
        }
      }
    }

    @media (min-width: $lg) {
      max-width: rem(564);
    }

    a {
      @include link-underline();
    }

    p {
      margin-bottom: rem(21);

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $lg) {
        margin-bottom: rem(20);
      }
    }
  }
}
