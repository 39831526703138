.not-found {
	padding: rem(100) 0;
	text-align: center;

	@media (min-width: $lg) {
		min-height: calc(100vh - 558px);
	}

	.container {
		& > * {
			margin-bottom: rem(20);
			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			@include link-underline();
		}
	}
}