.highlighted {
	color: $color--primary;

	&-background {
		@media (min-width: $lg) {
			border-radius: rem(20);
			background: rgba($color--primary, 0.05);
			padding: 0 rem(8);
		}
	}
}

p > .highlighted {
	color: rgba($color--primary, 0.75);
	font-weight: 700;
}

.bold {
	font-weight: 700;
}