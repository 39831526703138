.image-text {
	padding: rem(80) 0;
	background: $color--primary;

	@media (min-width: $lg) {
		padding: rem(55) 0;
	}

	.section {
		&__header {
			margin-bottom: 0;
			max-width: 100%;
		}
	}

	&__row {
		@media (min-width: $lg) {
			align-items: center;
		}
	}

	&__col {
		margin-bottom: rem(60);

		@media (min-width: $lg) {
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&--text {
			@media (min-width: $lg) {
				flex-basis: 46.5%;
				max-width: 46.5%;
			}

			.has-icon & {
				@media (min-width: $lg) {
					flex-basis: 50%;
					max-width: 50%;
				}
			}
		}

		&--img {
			@media (min-width: $lg) {
				flex: 1;
				max-width: initial;
			}

			@media (min-width: $xxl) {
				margin-right: rem(-20);
			}

			.has-icon & {
				@media (min-width: $xxl) {
					margin-right: rem(-186);
				}
			}
		}
	}

	&__img {
		position: relative;
		max-width: rem(328);
		margin: 0 auto;

		@media (min-width: $lg) {
			max-width: rem(601);

			.has-icon & {
				max-width: rem(576);
			}
		}

		img {
			display: block;
			width: 100%;
 		}
	}

	&__additional-image {
		position: absolute;
		top: 0;
		left: 0;

		@media (min-width: $lg) {
			left: 0;
		}

		@media (min-width: 1184px) {
			left: rem(-58);
		}

		img {
			max-width: rem(113);

			@media (min-width: $lg) {
				max-width: rem(200);
			}
		}
	}

	&__buttons {
		display: flex;
		flex-wrap: wrap;
		margin-top: rem(27);
		margin-bottom: rem(-10);

		@media (min-width: $lg) {
			margin-top: rem(30);
			margin-bottom: rem(-20);
		}

		.btn {
			min-width: rem(130);
			margin-right: rem(10);
			margin-bottom: rem(10);

			@media (min-width: $lg) {
				min-width: rem(184);
				margin-right: rem(20);
				margin-bottom: rem(20);
			}
		}
	}
}