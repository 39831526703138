.content-columns {
  padding: rem(68) 0;

  @media (min-width: $lg) {
    padding: rem(72) 0;
  }

  &.is-contact {
    .section {
      &__header {
        margin-bottom: rem(34);
      }

      &__title {
        gap: rem(0) rem(7);

        @media (min-width: $lg) {
          gap: rem(4) rem(11);
        }
      }
    }

    .content-columns {
      &__col {
        &:first-child {
          margin-bottom: rem(42);

          @media (min-width: $lg) {
            margin-bottom: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &__img {
        margin: 0 auto;
        max-width: rem(375);
        @media (min-width: $lg) {
          max-width: 100%;
        }

        img {
          @media (min-width: $lg) {
            max-width: rem(482);
            width: 100%;
          }
        }
      }
    }
  }

  .section {
    &__header {
      margin-bottom: rem(34);

      @media (min-width: $lg) {
        margin-bottom: 0;
      }
    }

    &__title {
      display: flex;
      gap: rem(8);
      flex-wrap: wrap;
      margin-bottom: rem(25);

      @media (min-width: $lg) {
        margin-bottom: rem(26);
        gap: rem(4);

        &__no_margin {
          margin-bottom: 0;
        }
      }
    }

    &__description {
      @include font(18, 34);

      p {
        margin-bottom: rem(34);

        &:last-child {
          margin-bottom: 0;
        }

        @media (min-width: $lg) {
          margin-bottom: rem(30);
        }
      }

      @media (min-width: $lg) {
        @include font(20, 30);
      }
    }
  }

  &__col {
    &:first-child {
      @media (min-width: $lg) {
        padding-right: rem(22);
      }
    }
    &:last-child {
      @media (min-width: $lg) {
        padding-top: rem(12);
        padding-left: rem(10);
      }
      .section__header {
        margin-bottom: 0;
      }
    }
  }
}
