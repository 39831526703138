.contact {
	padding: rem(80) 0;

	@media (min-width: $lg) {
		padding: rem(110) 0;
	}

	.section {
		&__header {
			margin-bottom: rem(54);

			@media (min-width: $lg) {
				max-width: rem(500);
			}
		}
		&__subtitle {
			margin-bottom: rem(16);
		}
		&__title {
			margin-bottom: rem(16);
		}
	}

	.form {
		padding: rem(35) rem(20);
		background: $color--4;
		border-radius: rem(10);

		@media (min-width: $lg) {
			padding: rem(40) rem(24);
		}

		&__input, &__textarea {
			border: rem(1) solid rgba(0, 0, 0, 0.1);
		}
	}
}