.posts-grid {
	padding: rem(77) 0 rem(112) 0;
	background: rgba($color--primary, 0.05);

	@media (min-width: $lg) {
		padding: rem(110) 0 rem(90) 0;
	}

	.section {
		&__header {
			& > *:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__row {
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-start;
		overflow-x: auto;
		@include scrollbar-hidden();
		margin: 0 rem(-8);

		@media (min-width: $lg) {
			margin: 0 rem(-12);
			justify-content: center;
		}
	}

	&__button {
		display: flex;
		justify-content: center;
		margin-top: rem(57);

		.btn {
			min-width: rem(208);
		}

		@media (min-width: $lg) {
			margin-top: rem(76);
		}
	}
}