.pulse-anim {
	animation: pulse-anim 3s ease-out;
	animation-iteration-count: infinite;
	opacity: 0.5;
}

@keyframes pulse-anim {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 1.0;
	}
	100% {
		opacity: 0.5;
	}
}