.process {
	padding: rem(48) 0 rem(76) 0;

	@media (min-width: $lg) {
		padding: rem(81) 0;
	}

	.section {
		&__title {
			margin-bottom: rem(25);

			@media (min-width: $lg) {
				margin-bottom: rem(30);
				display: flex;
				gap: 0 rem(8);
				flex-wrap: wrap;
			}
		}
		&__header {
			margin-bottom: rem(25);

			@media (min-width: $lg) {
				margin-bottom: rem(30);
			}
		}
	}

	&__row {
		@media (min-width: $lg) {
			margin: 0 rem(-40);
		}
	}

	&__col {
		&--text {
			margin-bottom: rem(40);

			@media (min-width: $lg) {
				margin-bottom: 0;
			}
		}

		@media (min-width: $lg) {
			padding-inline: rem(40);
		}
	}

	&__img {
		display: none;

		@media (min-width: $lg) {
			display: block;
			margin-top: rem(94);
		}

		&--mobile {
			display: block;

			@media (min-width: $lg) {
				display: none;
			}
		}
	}

	&__cards {
		margin-bottom: rem(70);

		@media (min-width: $lg) {
			margin-bottom: 0;
		}

		.process-card {
			margin-bottom: rem(20);
			padding: rem(40);
			border-radius: rem(10);

			& > *:last-child {
				margin-bottom: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			&--lightgreen {
				background: $color--7;
			}

			&--lightblue {
				background: $color--4;
			}

			&__icon {
				max-width: rem(52);
				margin-bottom: rem(15);

				@media (min-width: $lg) {
					margin-bottom: rem(10);
				}
			}

			&__title {
				margin-bottom: rem(15);

				@media (min-width: $lg) {
					margin-bottom: rem(10);
					@include font(38, 51);
				}
			}

			&__description {
				font-family: $font-secondary;
				@include font(18, 24);
			}
		}
	}
}