.fund {
  background: $color--3;
  padding-top: rem(128);
  padding-bottom: rem(60);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  @media (min-width: $lg) {
    padding-top: rem(210);
    padding-bottom: rem(210);
    min-height: rem(552);
  }
  font-weight: 700;
  h1 {
    color: white;
    font-size: 85px;
    @media (min-width: $lg) {
      font-size: 92px;
    }
  }
  h3 {
    color: white;
    font-size: 24px;
    @media (min-width: $lg) {
      font-size: 21px;
    }
    line-height: 25.6px;
    padding: 1rem 1rem;
    text-align: center;
  }
  button {
    margin-top: 2rem;
    background: rgba(255, 214, 0, 1);
    color: black;
    padding: 12px 20px 12px 20px;
    border: none;
    border-radius: 12px;
    font-size: 22px;
    cursor: pointer;
    @media (min-width: $lg) {
      font-size: 15px;
    }
  }
}
