.faq {
  background-color: #224774;

  @media (min-width: 768px) {
    padding-top: rem(148);
    padding-bottom: rem(148);
  }
  padding: 5rem 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    text-align: center;
    font-weight: 700;
    font-size: 21px;
    color: white;
  }

  .questions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 3.25rem;
    width: 100%;
    max-width: 800px;

    @media (max-width: $lg) {
      justify-content: flex-start;
    }

    div {
      min-width: rem(1000);
      @media (max-width: $lg) {
        padding: 0 7.75rem;
      }

      @media (max-width: $md) {
        padding: 0 14.25rem;
      }

      @media (max-width: $sm) {
        padding: 0 20rem;
      }
    }

    div > details {
      padding: 1rem;
      border-bottom: 1px solid rgba($color--light, 0.2);
    }

    div > details > summary {
      list-style: none;

      color: white;
      font-size: rem(12);
      font-weight: 700;
    }

    .icon {
      transition: transform 0.3s ease;
      margin-right: 10px;
    }

    div > details[open] {
      border-bottom: none;
    }

    div > details[open] .icon {
      transform: rotate(-90deg);
    }

    div > details[open] > p {
      border-bottom: 1px solid rgba($color--light, 0.2);
    }

    div > details {
      width: 100%;
    }

    div > details > p {
      color: white;
      font-size: rem(12);
      font-weight: 400;
      padding: 0 2rem 2rem rem(24);
      margin-top: rem(24);
    }

    hr {
      border: 1px solid white;
      margin-top: rem(24);
    }
  }
}
