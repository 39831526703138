/**
 * Fonts.
 */

$font-base: "SF Pro Display", sans-serif;
$font-secondary: "Inter", sans-serif;

/**
 * Colors.
 */

$color--dark: #000000;
$color--light: #ffffff;

$text-color-base: #000000;
$text-color-secondary: #141e1b;
$text-color-white: #ffffff;
$link-color: null;
$link-hover-color: null;

$color--primary: #017aff;
$color--secondary: #06233f;

$color--2: #b0c9e8;
$color--3: #154777;
$color--4: #f2f8ff;
$color--5: #d4af37;
$color--6: #10375b;
$color--7: #e6fffc;
$color--8: #143255;
$background: #ffffff;

$color--error: #ff9494;

$default-transition-speed: 0.2s;

/**
 * Container.
 */

$container: 1152;
$container-padding: 16;

/**
 * Breakpoints.
 */

$xs: 375px;
$sm: 577px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1440px;
