#about {
  scroll-margin-top: 6.875rem;
}

.video {
  padding: rem(54) 0;
  background: rgba($color--primary, 0.05);

  @media (min-width: $lg) {
    padding: rem(198) 0 rem(148) 0;
  }

  .section {
    &__header {
      margin-bottom: rem(20);

      @media (min-width: $lg) {
        margin-bottom: rem(30);
      }
    }

    &__subtitle {
      margin-bottom: rem(15);

      @media (min-width: $lg) {
        margin-bottom: rem(10);
      }
    }

    &__title {
      margin-bottom: rem(20);
    }

    &__description {
      @media (min-width: $lg) {
        display: none;
      }

      a {
        color: $color--primary;
      }
    }
  }

  &__col {
    &--text-mobile {
      display: none;

      @media (min-width: $lg) {
        display: block;
      }

      .section {
        &__header {
          max-width: 100%;
          display: block;
          margin-bottom: 0;
        }
        &__description {
          max-width: 100%;
          display: block;
        }
      }
    }
  }

  &__buttons {
    .btn {
      margin-bottom: rem(10);

      @media (min-width: $lg) {
        margin-bottom: rem(15);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    cursor: pointer;
  }
}
