/**
 * Site base styles.
 */

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,
body,
h1,
h2,
h3,
p,
a,
img,
header,
footer,
nav,
ol,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: rem(16);
  line-height: 1.375;
  font-weight: 400;
  color: rgba($color--dark, 0.75);
  font-family: $font-base;
  @include scrollbar($color--secondary, $color--2);

  &.overflow {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-base;
  font-weight: 600;
  color: $color--dark;
}

h1,
.h1 {
  @include font(38, 40);
  font-weight: 700;

  @media (min-width: $lg) {
    @include font(60, 56);
  }
}

h2,
.h2 {
  @include font(30, 38);

  @media (min-width: $lg) {
    @include font(46, 54);
  }
}

h3,
.h3 {
  @include font(40, 48);

  @media (min-width: $lg) {
    @include font(45, 46);
  }
}

h4,
.h4 {
  @include font(22, 28);
}

h5 {
  font-size: rem(24);
}

h6 {
  font-size: rem(18);
}

a {
  color: inherit;
  text-decoration: none;
  &:not([class]):hover {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}

input,
button {
  all: unset;
}

input,
textarea {
  cursor: text;
  &::placeholder {
    color: $text-color-base;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

input[type="submit"],
button {
  cursor: pointer;
}

.overflow {
  overflow: hidden;
}

textarea {
  font-family: $font-base;
}

main {
  overflow: hidden;

  @media (min-width: $lg) {
    padding-top: rem(70);
  }
}

.anchor {
  scroll-margin-top: 4rem;
}

.screen-reader-text {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  margin: -1px !important;
  white-space: nowrap !important;
  border: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}
