.main-footer {
  background: $color--8;
  font-family: $font-secondary;

  &__top {
    padding-top: rem(32);
    padding-bottom: rem(56);

    @media (min-width: $lg) {
      padding-top: rem(60);
      padding-bottom: rem(79);
    }
  }

  &__bottom {
    padding: rem(24) 0;
    // background: rgba($color--dark, 0.23);
    color: rgba($color--light, 0.5);
    @include font(16, 20);

    .container {
      @media (min-width: $lg) {
        padding-left: rem(27);
      }
    }

    span {
      display: block;
    }
  }

  &__container {
    @media (min-width: $lg) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    span {
      margin-top: 1rem;
    }
  }

  &__row {
    display: flex;
  }

  &__logo {
    position: relative;
    width: fit-content;

    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    &-socials {
      display: flex;
      align-items: start;
      justify-content: flex-start;
      gap: rem(20);
      flex-direction: row;
    }

    &-subtitle {
      margin-top: rem(7);
      font-weight: 700;
      @include font(16, 22);
      color: $color--5;
      margin-bottom: rem(7);
    }
  }

  &__button {
    &.btn--arrow {
      min-width: fit-content;
    }
  }

  &__divider {
    border: 1px solid rgba($color--light, 0.3);
    margin-top: 2rem;
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    gap: rem(21) 0;
    padding-top: rem(35);
    margin: rem(36) rem(-$container-padding) 0;
    position: relative;

    &::before {
      content: "";
      display: block;
      height: rem(1);
      width: calc(100% - 32px);
      background: rgba($color--light, 0.5);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: $lg) {
        display: none;
      }
    }

    @media (min-width: $lg) {
      padding-top: 0;
      margin-top: 0;
    }

    &-item {
      flex-basis: 50%;
      max-width: 50%;
      flex-grow: 0;
      padding: 0 rem($container-padding);

      @media (min-width: $lg) {
        flex-grow: 0;
        max-width: 33.33333%;
        flex-basis: 33.33333%;
      }
    }

    &-title {
      @include font(20, 24);
      color: $color--light;
      margin-bottom: rem(16);
      margin-top: 0;
      max-width: rem(134);
      font-family: $font-secondary;
      font-weight: 400;

      @media (min-width: $lg) {
        max-width: 100%;
      }

      .main-footer__menu-item--socials & {
        margin-bottom: rem(24);
      }

      &__margin-top {
        @include font(18, 24);
        color: $color--light;
        margin-bottom: rem(16);
        margin-top: 0;
        max-width: rem(134);
        font-family: $font-secondary;
        font-weight: 400;

        @media (min-width: $lg) {
          max-width: 100%;
        }
        margin-top: 1rem;
      }
    }

    &-list {
      padding: 0 0 0.5rem 0;
    }

    &-link {
      @include font(16, 20);
      color: rgba($color--light, 0.5);
      margin-bottom: rem(16);
      &:last-child {
        margin-bottom: 0;
      }

      a:hover {
        text-decoration: none;
      }

      &:not(.main-footer__menu-link--social) {
        a {
          @include link();
          &:hover {
            color: $color--light;
          }
        }
      }

      &--social {
        a {
          &:hover {
            span {
              color: $color--light;
              background-position: 0 100%;
              background-size: 100% rem(1);
            }

            svg {
              rect {
                fill: $color--light;
              }
              path {
                fill: $color--primary;
              }
            }
          }

          svg {
            rect,
            path {
              transition: fill $default-transition-speed;
            }
          }

          span {
            @include link();
          }
        }
      }
    }
  }
}
