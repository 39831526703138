.container {
  max-width: rem($container + $container-padding * 2);
  padding-left: rem(16);
  padding-right: rem(16);
  margin: 0 rem(44);

  &__no-max-width {
    padding-left: rem(16);
    padding-right: rem(16);
    margin: 0 rem(27) 0 rem(44);
  }

  &__no_margin {
    max-width: rem($container + $container-padding * 2);
    padding-left: rem(16);
    padding-right: rem(16);
  }

  &__end {
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
}

.container-center {
  max-width: 90rem;
  padding-left: rem(60);
  padding-right: rem(60);
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: rem($container-padding * -1);
  margin-left: rem($container-padding * -1);

  &--align-center {
    display: flex;
    flex-wrap: wrap;
    margin-right: rem($container-padding * -1);
    margin-left: rem($container-padding * -1);
    align-items: center;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;

  padding: 0 rem($container-padding);
}

.widget {
  &__desktop {
    @media (max-width: $md) {
      display: none;
    }
  }
}

[class*="col-"] {
  flex-grow: 0;
  flex-shrink: 0;

  padding: 0 rem($container-padding);
}

@for $i from 1 through 12 {
  .col-#{$i} {
    flex-basis: calc($i / 12) * 100%;

    max-width: calc($i / 12) * 100%;
  }
}

// Tablet
@for $i from 1 through 12 {
  .col-md-#{$i} {
    @media (min-width: $md) {
      flex-basis: calc($i / 12) * 100%;

      max-width: calc($i / 12) * 100%;
    }
  }
}

// Small Desktop
@for $i from 1 through 12 {
  .col-lg-#{$i} {
    @media (min-width: $lg) {
      flex-basis: calc($i / 12) * 100%;

      max-width: calc($i / 12) * 100%;
    }
  }
}

// Desktop
@for $i from 1 through 12 {
  .col-xl-#{$i} {
    @media (min-width: $xl) {
      flex-basis: calc($i / 12) * 100%;

      max-width: calc($i / 12) * 100%;
    }
  }
}
