.hero {
  position: relative;
  background: $color--3;
  padding-top: rem(128);
  // padding-bottom: rem(60);

  @media (min-width: $lg) {
    padding-top: rem(60);
    min-height: rem(552);
  }

  &.hero--small {
    @media (min-width: $lg) {
      min-height: rem(400);
      padding-top: rem(32);
    }
  }

  &__container {
    min-height: inherit;

    @media (min-width: $lg) {
      min-height: inherit;
    }
  }

  &__row {
    @media (min-width: $lg) {
      min-height: inherit;
      align-items: flex-end;
    }

    &.has-video {
      @media (min-width: $lg) {
        align-items: center;
      }
    }
  }

  &__col {
    &--text {
      & > *:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $lg) {
        flex-basis: 43%;
        max-width: 43%;
        padding-bottom: rem(36);
        align-self: center;
      }
    }

    &--img {
      margin-top: rem(30);
      display: flex;
      justify-content: flex-end;

      @media (min-width: $lg) {
        margin-top: 0;
        display: block;
        flex-basis: 57%;
        max-width: 57%;
      }
    }

    &--video {
      margin: rem(30) 0;

      @media (min-width: $lg) {
        margin: 0;
        display: block;
        flex-basis: 57%;
        max-width: 57%;
      }
    }

    &--widget_container {
      position: relative;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
    }

    &--widget {
      display: none;

      @media (min-width: $lg) {
        position: absolute;
        top: -8.375rem;
        left: 41.625rem;
        display: flex;
        justify-content: center;
        margin-left: 11rem;
      }
    }
  }

  &__title {
    color: $color--2;
    padding: 0 1rem;
    margin-bottom: rem(10);

    &.h2 {
      @include font(48, 50);
      color: $color--light;
      margin-bottom: 3rem;
      @media (min-width: $lg) {
        @include font(56, 64);
      }
    }

    @media (min-width: $lg) {
      margin-bottom: rem(40);
    }
  }

  &__subtitle {
    color: $color--light;
    @include font(25, 38);
    margin-bottom: rem(20);

    &--big {
      color: $color--light;
      padding: 0 1rem;
      margin-bottom: rem(20);
      @include font(35, 48);
    }
  }

  &__description {
    color: rgba($color--light, 0.75);
    margin-bottom: rem(20);

    @media (min-width: $lg) {
      @include font(22, 26);
      margin-bottom: rem(30);
    }
  }

  &__highlight_container {
    color: $color--primary;
    margin-bottom: rem(20);

    &__highlight {
      @include font(22, 26);
      line-height: 1.52;
      border-radius: rem(20);
      box-shadow: $color--2 0 0 10px;

      background: rgba($color--light, 0.95);
      padding: rem(8) rem(16);
      margin-bottom: rem(30);
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: rem(10);

    @media (min-width: $lg) {
      gap: rem(15);
    }

    .btn {
      padding-inline: rem(17);
      min-width: fit-content;

      @media (min-width: $lg) {
        padding-inline: rem(28.5);
      }
    }
  }

  &__image {
    width: rem(393);
    margin-right: rem(-60);

    @media (min-width: $lg) {
      width: auto;
      margin-right: 0;
    }

    img {
      @media (min-width: $lg) {
        max-width: rem(856);
        margin-left: rem(-6);
      }

      .hero--small & {
        @media (min-width: $lg) {
          margin-left: rem(-14);
        }
      }
    }
  }

  &__video {
    position: relative;
    margin-top: 2em;
    z-index: 0;
    @media (min-width: $lg) {
      margin-top: 0;
    }

    .vimeo {
      &--frame {
        padding: 56.21% 0 0 0;
        position: relative;
        z-index: 0;

        iframe {
          width: 100%;
          height: 100%;
          border-radius: 20px;
          border: none;
        }
      }
    }

    select {
      display: none;
    }

    .load_video {
      position: absolute;
      max-width: 150px;
      z-index: 1;
      @media (min-width: $lg) {
        display: flex;
        max-width: 100%;
      }
      top: -42px;
      padding: 10px;
      right: -10px;
      color: rgb(0, 0, 0);
      appearance: none;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      text-align: right;

      &:before {
        content: attr(data-name);
      }

      li {
        display: none;
        cursor: pointer;
        width: 100%;
        padding: 10px 20px;
        margin-left: 5px;
        background-color: #565656;
        color: rgb(255, 255, 255);

        &:hover {
          background-color: #017aff;
          color: #fff;
        }
      }

      &.open {
        top: -52px;
        li {
          display: inline-block;
          @media (min-width: $lg) {
            display: inline;
          }
        }

        &:before {
          content: "";
        }
      }
    }
  }

  &__phone-svg {
    z-index: 1;
    position: absolute;
    top: 16rem;
    right: 15rem;
    @media (max-width: calc($xl + 100px)) {
      display: none;
    }
  }

  &__tablet-svg {
    position: absolute;
    top: 7.5rem;
    right: 0;
    @media (max-width: calc($xl + 100px)) {
      display: none;
    }
  }

  &__coin-gif {
    z-index: 2;
    position: absolute;
    top: 33.5rem;
    right: 24rem;
    @media (max-width: calc($xl + 100px)) {
      display: none;
    }
  }

  .hide-on-mobile {
    display: none;
    @media (min-width: $lg) {
      display: block;
    }
  }
}
