.image-list {
  padding: rem(64) 0;

  &.no--padding {
    @media (max-width: $lg) {
      padding: 0;
    }
  }

  @media (min-width: $lg) {
    padding: rem(125) 0;
  }

  &.primary-bg {
    background: rgba($color--primary, 0.05);
  }

  &.image--small {
    @media (min-width: $lg) {
      padding: rem(112) 0 0 0;
    }
  }

  .section {
    &__header {
      margin-bottom: rem(30);

      @media (min-width: $lg) {
        margin-bottom: rem(40);
      }
    }

    &__subtitle {
      margin-bottom: rem(15);

      @media (min-width: $lg) {
        margin-bottom: rem(10);
      }
    }
    &__title {
      margin-bottom: rem(24);

      @media (min-width: $lg) {
        margin-bottom: rem(20);
      }
    }

    &__description {
      &__margin {
        margin-top: rem(20);
        margin-bottom: rem(20);
        @media (min-width: $lg) {
          padding: 0 10rem 0 0;
        }
      }

      @media (min-width: $lg) {
        max-width: rem(564);
      }
    }
  }

  &__row {
    &--start {
      align-items: start;
    }

    @media (min-width: $lg) {
      align-items: center;
    }
  }

  &__col {
    &--list {
      @media (min-width: $lg) {
        max-width: 54%;
        flex-basis: 54%;
      }

      .image--small & {
        @media (min-width: $lg) {
          max-width: 60%;
          flex-basis: 60%;
        }
      }
    }

    &--img {
      @media (min-width: $lg) {
        max-width: 46%;
        flex-basis: 46%;
      }

      .image--small & {
        @media (min-width: $lg) {
          max-width: 40%;
          flex-basis: 40%;
        }
      }
    }
  }

  &__list {
    @media (min-width: $lg) {
      max-width: rem(604);
    }

    .list-item {
      margin-bottom: rem(24);
      display: flex;
      padding-right: rem(18);

      &:last-child {
        margin-bottom: 0;
      }

      &__icon {
        margin-right: rem(19);
        min-width: rem(31);
        border-radius: 50%;
        height: rem(31);
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 192, 173, 0.25);
      }

      &__description {
        color: $color--dark;
        display: flex;
        align-items: center;

        br {
          display: none;

          @media (min-width: $lg) {
            display: block;
          }
        }

        &--light {
          color: $color--light;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: $lg) {
      justify-content: flex-start;
    }
    gap: rem(10);
    margin-top: rem(46);

    @media (min-width: $lg) {
      margin-top: rem(40);
      gap: rem(24);
    }

    .btn {
      padding-inline: rem(12.4);

      @media (min-width: $lg) {
        padding-inline: rem(19.2);
      }
    }
  }

  .styled-container {
    @media (max-width: $lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__img {
    max-width: rem(328);
    margin: rem(60) auto 0;

    @media (min-width: $lg) {
      max-width: 100%;
      margin: 0;
      margin-top: 1rem;
    }

    img {
      @media (min-width: $lg) {
        max-width: rem(778);
      }
    }

    &__styled {
      max-width: 450px;
      max-height: 300px;

      @media (max-width: $lg) {
        display: flex;
        justify-content: center;
      }

      padding: 1rem 1rem;
      margin: 2rem 0;
      background: white;
      border-radius: 2.5rem;
      box-shadow: 0px 32px 48px -8px rgba(0, 0, 0, 0.1),
        0px 0px 14px -4px rgba(0, 0, 0, 0.05);

      &__big {
        padding: 2rem 2rem;
        margin: 4rem 0;
        background: white;
        border-radius: 2.5rem;
        box-shadow: 0px 32px 48px -8px rgba(0, 0, 0, 0.1);

        min-width: 40rem;
        min-height: 30rem;
      }

      &__offset {
        @media (min-width: $lg) {
          padding: 2rem 2rem;
          margin: 4rem 0;
          position: relative;
          top: -7rem;
        }
      }
    }
  }
}
